import cn from 'classnames'
import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react'

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean
  openClassName: string
  closedClassName: string
  closingClassName?: string
  skipOutTransition?: boolean
}

type State = 'open' | 'opening' | 'closed' | 'closing'

const Reveal = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    isOpen,
    children,
    openClassName,
    closedClassName,
    closingClassName,
    className,
    skipOutTransition,
    ...rest
  } = props
  const [state, setState] = useState<State>('closed')
  useEffect(() => {
    if (isOpen) {
      if (state === 'closed') {
        setState('opening')
      } else if (state === 'opening' || state === 'closing') {
        // Set a timeout to let React render at least one frame to trigger the transition
        setTimeout(() => {
          setState('open')
        }, 1)
      }
    } else {
      if (state == 'open') {
        setState(skipOutTransition ? 'closed' : 'closing')
      }
    }
  }, [isOpen, skipOutTransition, state])
  if (state == 'closed') return null
  return (
    <div
      ref={ref}
      onTransitionEnd={() => {
        if (state === 'closing') {
          setState('closed')
        }
      }}
      className={cn(
        state === 'closing' && closingClassName ? closingClassName : className,
        state === 'open' ? openClassName : closedClassName,
      )}
      {...rest}
    >
      {children}
    </div>
  )
})

Reveal.displayName = 'Reveal'

export default Reveal
