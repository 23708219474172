import {
  CommonSectionSettingsColorThemeEnum,
  Navigation,
} from 'framework/strapi/types'
import React, { FC } from 'react'
import { NavigationState } from '../useNavigation'
import NavigationLink from './NavigationLink'

interface Props {
  state: NavigationState
  colorTheme: CommonSectionSettingsColorThemeEnum
  navigation: Navigation
}

const MainNavigationDesktop: FC<Props> = ({
  state,
  navigation,
  colorTheme,
}) => {
  const css = {
    logoCategoriesWrapper: 'hidden lg:flex gap-x-10 flex-1 justify-between',
    categoriesWrapper: 'flex',
  }

  return (
    <div className={css.logoCategoriesWrapper}>
      <div className={css.categoriesWrapper}>
        {navigation.items?.map((item, i) => (
          <NavigationLink
            state={state}
            navigationId={navigation.slug}
            key={i}
            item={item}
            colorTheme={colorTheme}
          />
        ))}
      </div>
    </div>
  )
}

export default MainNavigationDesktop
