export const USERSETTINGS_COOKIENAME = 'Elekio.User.Settings.2'
export interface UserSettingsCookie {
  region: string | null
  language: string | null
  country: string | null
  channel: string | null
}

export const USERINFO_COOKIENAME = 'Eleiko.User.Info.1'
export interface UserInfoCookie {
  region: string | null
  country: string | null
}

export const COUNTRYCHECK_COOKIENAME = 'Eleiko.CountryCheck.1'
export interface CountryCheckCookie {
  closed: boolean
}
