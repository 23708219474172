import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkDispatch,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector as useReduxSelector,
} from 'react-redux'
import channel from './channel'

const reducer = combineReducers({
  channel,
})

// Catch Redux thunk errors
const logger: Middleware = (_: MiddlewareAPI) => (next) => (action) => {
  // // TODO Performance intense should be removed in prod
  // console.log(`Action ${action.type} Payload:`, action.payload)
  // if (action.error) {
  //   console.error(`${action.type} was rejected with error`, action.error)
  // }
  return next(action)
}

const createStore = (preloadedState: Partial<StateType>) => {
  // TODO Called everytime a page is created
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(logger) as any,
    preloadedState,
    devTools: true,
  })
}

export type StateType = ReturnType<typeof reducer>
type AppDispatch = ThunkDispatch<StateType, null, AnyAction>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<StateType> = useReduxSelector

export default createStore
