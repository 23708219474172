import React, { FC } from 'react'
import FooterSection from 'components/common/Footer'
import PreviewBanner from '../PreviewBanner'
import { useRouter } from 'next/router'
import MainNavigation from '../MainNavigation'
import { LayoutProps } from 'framework/common/server/getLayoutProps'
import ProgressBar from 'components/ui/ProgressBar'
import dynamic from 'next/dynamic'
import Toast from '../Toast'
import cn from 'classnames'
import LoadingDots from 'components/ui/LoadingDots'
import CookieConsent from '../CookieConsent'
import useUIStore from 'store/ui'

const CountrySelector = dynamic(() => import('../CountrySelector'), {
  ssr: false,
})

const QuickSearch = dynamic(() => import('components/QuickSearch'), {
  ssr: false,
})

const Cart = dynamic(() => import('components/Cart'), {
  ssr: false,
})

const Favorites = dynamic(() => import('components/Favorites'), {
  ssr: false,
})

const CountryNotification = dynamic(() => import('../CountryNotification'), {
  ssr: false,
})

const NewsLetterDialog = dynamic(() => import('../Footer/NewsLetterDialog'), {
  ssr: false,
})

interface Props {
  pageProps: LayoutProps
  children?: React.ReactNode
}

const Layout: FC<Props> = ({
  children,
  pageProps: {
    navigations,
    hideLayout,
    hideNavigation,
    isCheckout,
    floatingNavigation,
    navigationColorTheme,
    footer,
  },
}) => {
  const router = useRouter()
  const ui = useUIStore()

  if (hideLayout || !navigations || !footer) return <>{children}</>

  // TODO navigations is empty on the first render
  const navigation = navigations['main-menu']
  const secondaryNavigation = navigations['secondary-menu']
  const footerNavigation1 = navigations['footer-information']
  const footerNavigation2 = navigations['footer-customer-service']
  const footerNavigation3 = navigations['footer-support']
  const topBanner = navigations['top-banner']

  return (
    <div
      id="root"
      className={cn({
        'w-screen overflow-x-hidden': ui.isOpen && ui.hideScroll,
      })}
    >
      <ProgressBar isAnimating={ui.isLoading} />
      {navigation && (
        <MainNavigation
          navigation={navigation}
          hideNavigation={hideNavigation}
          isCheckout={isCheckout}
          secondaryNavigation={secondaryNavigation}
          floating={floatingNavigation}
          navigationColorTheme={navigationColorTheme}
          topBanner={topBanner}
        />
      )}
      <main className="fit">
        {router.isFallback ? <LoadingDots /> : children}
      </main>
      {!isCheckout && <Cart />}
      {!isCheckout && <Favorites />}
      <QuickSearch />
      <CountrySelector />
      <CountryNotification />
      <NewsLetterDialog />
      <Toast />
      {/* Portal of BundelBuilder modal, We don't want to load it unless it's needed so instead we just create the container */}
      <div id="bundleModalPortal" />
      {/* Portal of Lightbox. TODO move lightbox to the global state instead */}
      <div id="lightboxModalPortal" />
      <div id="upsellModalPortal" />
      <CookieConsent />
      <FooterSection
        {...footer}
        navigation1={footerNavigation1}
        navigation2={footerNavigation2}
        navigation3={footerNavigation3}
      />
      {router.isPreview && <PreviewBanner />}
    </div>
  )
}

export default Layout
