import { FC } from 'react'

interface Props {
  children?: React.ReactNode
}

const ButtonWrapper: FC<Props> = (props) => {
  return (
    <div className="flex flex-wrap gap-4 items-center w-full sm:w-auto">
      {props.children}
    </div>
  )
}

export default ButtonWrapper
