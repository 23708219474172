import Container from 'components/ui/Container'
import useEmblaCarousel from 'embla-carousel-react'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import useRecursiveTimeout from 'utils/useRecursiveTimeout'
import Link from 'components/ui/Link'
import Text from 'components/common/RichText'
import { EnvelopeIcon } from '@heroicons/react/24/solid'
import translate from 'utils/translate'
import { NavigationBanner, StrapiComponent } from 'framework/strapi/types'
import isInChannel from 'utils/isInChannel'
import { useAppSelector } from 'store'
import classNames from 'classnames'

interface Props {
  banners: StrapiComponent[]
}

const emblaOptions: {} = {
  align: 'start',
  skipSnaps: false,
  containScroll: 'trimSnaps',
  inViewThreshold: 1,
  loop: true,
}

const TopNavigation: FC<Props> = ({ banners }) => {
  const css = {
    navigationTopPanel:
      'relative z-30 h-12 bg-primary-dark flex- items-center- w-full overflow-hidden',
  }

  const { locale, country } = useAppSelector((s) => s.channel)

  // Flashes
  const [viewportRef, embla]: any = useEmblaCarousel(emblaOptions) // Add plugin
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselAutoplay = useCallback(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      setCurrentIndex((currentIndex + 1) % banners.length)
      embla.scrollNext()
    } else {
      setCurrentIndex(0)
      embla.scrollTo(0)
    }
  }, [banners.length, currentIndex, embla])

  const carouselAutoplayInterval = 10000
  const { play } = useRecursiveTimeout(
    carouselAutoplay,
    carouselAutoplayInterval,
  )
  const emblaOptionsUpdate = useMemo(
    () => ({
      loop: false,
      startIndex: currentIndex,
    }),
    [currentIndex],
  )
  const initEmbla = useCallback(() => {
    if (!embla) return
    embla.reInit(emblaOptionsUpdate) // Initialize Embla Options
  }, [embla, emblaOptionsUpdate])
  const [selectedBanner, setSelectedBanner] = useState(null)
  useEffect(() => {
    if (!embla) return

    if (embla) {
      setSelectedBanner(embla.selectedScrollSnap())
    }

    const wrap = document.querySelector('.embla.top-navigation')
    wrap?.classList.remove('embla--is-ready')

    embla
      .on('init', () => {
        wrap?.classList.add('embla--is-ready')
      })
      .on('reInit', () => {
        wrap?.classList.add('embla--is-ready')
      })
      .on('resize', () => {
        wrap?.classList.remove('embla--is-ready')
        initEmbla()
      })

    initEmbla()
    play()
  }, [play, embla, viewportRef, initEmbla])

  return (
    <div className={css.navigationTopPanel}>
      <div className="hidden h-full md:flex">
        <Container layout="Fluid" height="Grow">
          <div className="flex justify-end flex-1">
            <div className="z-20 flex">
              <div className="w-10 bg-gradient-to-l from-primary-dark" />
              <div className="flex items-center bg-primary-dark">
                <Link
                  href="/contact"
                  className="flex self-center space-x-2 text-sm font-bold tracking-wide text-white hover:underline"
                >
                  <EnvelopeIcon className="w-5 h-5" />
                  <span>{translate('contactUs')}</span>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {banners && (
        <div className="absolute inset-0 w-full h-full embla top-navigation">
          <div className="h-full embla__viewport" ref={viewportRef}>
            <div className="embla__container flex w-full h-full !transform-none">
              {banners.map((c, index) => {
                if (!embla || typeof embla.selectedScrollSnap !== 'function') {
                  return null
                }
                //const isSelected = embla && embla.selectedScrollSnap() === index
                const isSelected = index === selectedBanner

                if (c.__component !== 'navigation.banner') {
                  return null
                }
                const b = c as NavigationBanner
                if (
                  !isInChannel({
                    locale,
                    country: country!,
                    text: b.channel,
                  })
                ) {
                  return null
                }
                const content = (
                  <Container layout="Fluid">
                    <Text>{b.text}</Text>
                  </Container>
                )
                return (
                  <div
                    className={classNames(
                      'top-0 !left-0 !right-0 h-full opacity-0 transition-opacity duration-1000 embla__slide flex-shrink-0 w-full text-white font-medium text-sm flex flex-col items-center justify-center text-center',
                      isSelected
                        ? 'embla-slide-fade-selected'
                        : 'embla-slide-fade-unselected',
                    )}
                    key={b.id}
                  >
                    {b.url?.src ? (
                      <Link href={b.url.src}>{content}</Link>
                    ) : (
                      content
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TopNavigation
