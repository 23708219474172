import { DynamicComponentProps } from 'framework/common/types'

interface isInChannel {
  locale: string
  country?: string
  text?: string | null
  defaultIfEmpty?: boolean
}

const isInChannel = ({
  locale,
  text,
  country: _country,
  defaultIfEmpty = true,
}: isInChannel) => {
  const ch = text?.replace(/\s+/g, '')?.toLowerCase()
  if (!ch) return defaultIfEmpty

  const channels = ch.split(',')

  // Empty string means wildcard match all channels (*)
  if (channels.length == 0) return true

  // Separate positive and negative channels
  const positiveChannels: string[] = []
  const negativeChannels: string[] = []

  for (const channel of channels) {
    if (channel.startsWith('!')) {
      negativeChannels.push(channel.slice(1))
    } else {
      positiveChannels.push(channel)
    }
  }

  const positiveMatch = match(locale, positiveChannels)
  const negativeMatch = match(locale, negativeChannels)
  if (positiveMatch === 0) return false
  return positiveMatch >= negativeMatch
}

const match = (locale: string, channels: string[]) => {
  const [, country] = locale.split('-')
  const isGlobalChannel = country === undefined
  let score = 0
  for (const channel of channels) {
    const [channelLanguage, channelCountry] = channel.split('-')
    if (channel === locale) {
      score = Math.max(score, 10)
      continue
    }
    if (channel === '*') {
      score = Math.max(score, 1)
      continue
    }
    if (channelLanguage === '*' && channelCountry === country) {
      score = Math.max(score, 5)
      continue
    }
    if (isGlobalChannel && channel === 'global') {
      score = Math.max(score, 3)
      continue
    }
  }
  return score
}
export const isComponentPublishedInChannel = (
  locale: string,
  c: DynamicComponentProps,
) => isInChannel({ locale, text: (c as any).channel })

export default isInChannel
