import { StrapiFileComponent } from 'framework/strapi/types'
import NextImage, { ImageProps } from 'next/image'
import { FC } from 'react'
import { getStrapiFileMediaType } from 'utils/get-strapi-file-mime-type'
import getAbsoluteImageUrl from 'utils/getAbsoluteImageUrl'
import translate from 'utils/translate'

interface Props
  extends Omit<
    ImageProps,
    'src' | 'loader' | 'quality' | 'blurDataUrl' | 'alt' | 'layout'
  > {
  image: StrapiFileComponent
  alt?: string
  size?: '4x5' | '5x4'
  isThumbnail?: boolean
  autoPlay?: boolean
}

const CmsImage: FC<Props> = (props) => {
  const { image, size } = props
  const { isThumbnail, autoPlay, ...rest } = props
  if (!image) {
    console.error('Image is null, Render fallback image')
    // TODO render fallback
    return null
  }
  const type = getStrapiFileMediaType(image, size)

  if (type === 'image') {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <Image {...rest} />
  }
  if (type === 'video' || type === 'normalvideo') {
    return <Video {...rest} autoPlay={autoPlay} />
  }
  if (type === 'embed') {
    return isThumbnail ? (
      <Image
        {...rest}
        isYoutube={true}
        alt={rest.alt || image.alternativeText}
      />
    ) : (
      <Embed {...props} />
    )
  }
  if (type === 'embed-3d') {
    return <EmbedThreeDViewer {...props} isThumbnail={isThumbnail} />
  }

  console.error(`Could not render asset with type: ${type}`)
  return null
}

const Image: FC<Props & { isYoutube?: boolean }> = ({
  isYoutube,
  image,
  alt,
  size,
  ...rest
}) => {
  let url = isYoutube ? null : image.url

  let hasValidFormat = false
  if (size) {
    if (size == '4x5' && image.formats && '4x5' in image.formats) {
      hasValidFormat = true
    }
    if (size == '5x4' && image.formats && '5x4' in image.formats) {
      hasValidFormat = true
    }
  }

  if (size && hasValidFormat && image.formats[size]) {
    url = image.formats[size]?.url ?? null
  }

  if (!url) {
    let format = image.formats['large']
    if (!format) {
      console.log('Large format not found for image', image.name)
      format = image.formats['small']
    } else {
      url = format.url
    }
  }

  if (!url) {
    url = image.url
  }

  url = getAbsoluteImageUrl(url)

  let placeholder: 'blur' | 'empty' = 'empty'
  let blurDataURL: string | undefined = undefined

  if (image.formats && 'thumbnail' in image.formats) {
    const thumbnail = image.formats['thumbnail']
    if (thumbnail?.blurDataURL) {
      placeholder = 'blur'
      blurDataURL = thumbnail.blurDataURL
    }
  }

  return (
    <NextImage
      {...rest}
      src={url}
      placeholder={rest.priority ? undefined : placeholder}
      blurDataURL={rest.priority ? undefined : blurDataURL}
      alt={alt || image.alternativeText || image.caption || image.name}
    />
  )
}

const Video: FC<Props> = ({ image, autoPlay = true }) => {
  const url = getAbsoluteImageUrl(image.url)
  const cdnURL = process.env.NEXT_PUBLIC_CDN
  return (
    <video
      className="absolute bottom-0 right-0 object-cover min-w-full min-h-full"
      autoPlay={autoPlay}
      playsInline={autoPlay}
      controls={!autoPlay}
      loop={autoPlay}
      muted={autoPlay}
      poster={cdnURL ? `${cdnURL}/${url}/ik-thumbnail.jpg` : undefined}
    >
      <>
        {cdnURL && (
          <source src={`${cdnURL}/${url}?tr=f-webm`} type="video/webm" />
        )}
        <source src={url} type={image.mime} />
      </>
      Your browser does not support the video tag.
    </video>
  )
}

const Embed: FC<Props> = ({ image, alt }) => {
  return (
    <div className="absolute inset-0 flex flex-row items-center justify-items-center">
      <div className="w-full aspect-w-16 aspect-h-9">
        <iframe
          title={alt || translate('youtubeVideo')}
          src={image.url}
          id="youtube-video"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </div>
  )
}

const EmbedThreeDViewer: FC<Props> = ({ image, alt, isThumbnail }) => {
  return isThumbnail ? (
    <NextImage
      src="https://enterprise.eleiko.com/files/360_view_product_image.png"
      alt={alt || translate('londonDynamics')}
      width={image.width}
      height={image.height}
      className="bg-neutral-2"
    />
  ) : (
    <iframe
      title={alt || translate('londonDynamics')}
      src={image.url}
      id="ld-3d-model-viewer"
      allowFullScreen
      style={{ zIndex: 1000 }}
    />
  )
}
export default CmsImage
