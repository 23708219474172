import classNames from 'classnames'
import { navigationZIndex } from 'components/common/MainNavigation'
import Reveal from 'components/common/Reveal'
import React, { FC } from 'react'

interface DrawerProps {
  isOpen: boolean
  placement: 'left' | 'right'
  maxWidth?: string
  scrollable?: boolean
  children?: React.ReactNode
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  children,
  placement,
  maxWidth,
  scrollable,
}) => {
  return (
    <Reveal
      isOpen={isOpen}
      className={classNames(
        'fixed top-0 h-full w-full bg-white shadow-xl ease-in-out duration-300',
        placement === 'left' ? 'left-0' : 'right-0',
        maxWidth ?? 'max-w-xs',
        scrollable ? 'overflow-y-auto' : 'overflow-y-hidden',
        navigationZIndex.drawer,
      )}
      openClassName="translate-x-0"
      closedClassName={
        placement === 'left' ? '-translate-x-full' : 'translate-x-full'
      }
    >
      {children}
    </Reveal>
  )
}

export default Drawer
