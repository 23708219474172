import { Disclosure } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
  UserIcon,
  EnvelopeIcon,
  LifebuoyIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/solid'
import Link from 'components/ui/Link'
import cn from 'classnames'
import {
  ID,
  Navigation,
  NavigationCategoryItemColorThemeEnum,
  NavigationItem,
} from 'framework/strapi/types'
import React, { FC, useMemo, useState } from 'react'
import MainNavigationLogo from '../MainNavigationLogo'
import MainNavigationFeature from './MainNavigationFeature'
import { NavigationState } from '../useNavigation'
import translate from 'utils/translate'
import Drawer from 'components/ui/Drawer'
import Logos from 'components/common/Logos'
import dynamic from 'next/dynamic'
import Reveal from 'components/common/Reveal'

const SelectLocation = dynamic(
  () => import('components/common/SelectLocation'),
  {
    ssr: false,
  },
)

interface Props {
  state: NavigationState
  colorTheme?: NavigationCategoryItemColorThemeEnum
  navigation: Navigation
  secondaryNavigation?: Navigation
}

const MainNavigationMobile: FC<Props> = (props) => {
  const { state, navigation, secondaryNavigation } = props

  const css = {
    mobileSubCategoryButtonWrapperOpen:
      'flex gap-x-2 items-center cursor-pointer border-b-2',
    mobileSubCategoryButtonWrapperClosed:
      'flex gap-x-2 items-center cursor-pointer',
    mobileSubCategoryButtonOpen:
      'outline-none inline-block font-bold 2xl:text-xl text-left border-b-0',
    mobileSubCategoryButtonClosed:
      'outline-none inline-block font-bold 2xl:text-xl text-left border-b-2',
    mobileSubCategoryButtonIcon: 'h-3',
    mobileSubCategoryPanel: 'px-2 pb-2',
    mobileSubCategoryPanelLink:
      'block my-0.5 font-bold text-[15px] 2xl:text-lg',
    mobileSubCategoryLink:
      'inline-block font-bold 2xl:text-xl border-b-2 duration-300',
    mobileSubCategoryLinkHighlight:
      'inline-block font-bold 2xl:text-xl border-b-2 group-hover:translate-x-1 duration-300',
  }

  // Color Theme: Mobile Categories
  css.mobileSubCategoryButtonWrapperOpen = cn(
    css.mobileSubCategoryButtonWrapperOpen,
    'border-white',
  )
  css.mobileSubCategoryButtonOpen = cn(
    css.mobileSubCategoryButtonOpen,
    'border-white text-white',
  )
  css.mobileSubCategoryButtonClosed = cn(
    css.mobileSubCategoryButtonClosed,
    'border-white text-white',
  )
  css.mobileSubCategoryButtonIcon = cn(
    css.mobileSubCategoryButtonIcon,
    'text-white',
  )
  css.mobileSubCategoryPanelLink = cn(
    css.mobileSubCategoryPanelLink,
    'text-white hover:text-primary-2',
  )
  css.mobileSubCategoryLink = cn(
    css.mobileSubCategoryLink,
    'border-white text-white hover:text-primary-2',
  )
  css.mobileSubCategoryLinkHighlight = cn(
    css.mobileSubCategoryLinkHighlight,
    'border-transparent text-primary-2',
  )
  // Disclosure
  const [openDisclosure, setOpenDisclosure] = useState<ID>('')
  const handleDisclosureButton = (id: ID) => {
    if (openDisclosure === id) {
      setOpenDisclosure('')
    } else {
      setOpenDisclosure(id)
    }
  }
  const showMenu = useMemo(
    () =>
      (state.open && state.navigationOpen === navigation.slug) ||
      (secondaryNavigation != null &&
        secondaryNavigation.slug == state.navigationOpen),
    [state, navigation, secondaryNavigation],
  )
  const close = () => state.setMenu()

  const [nav1, nav2] = useMemo(() => {
    if (!navigation.items) {
      return []
    }
    if (navigation.items.length < 3) {
      return [navigation.items]
    }
    const n1 = navigation.items.slice(0, 2)
    const n2 = [
      ...navigation.items.slice(2),
      ...(secondaryNavigation?.items || []),
    ]
    return [n1, n2]
  }, [navigation, secondaryNavigation])

  return (
    <div className="lg:hidden">
      <Drawer isOpen={showMenu} placement="left" scrollable>
        <div className="flex flex-col justify-between min-h-full">
          <div>
            <div className="flex items-center justify-between h-16 px-6 bg-white">
              <MainNavigationLogo colorTheme="Default" />
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 -m-2 text-primary"
                onClick={() => state.setMenu()}
              >
                <span className="sr-only">{translate('closeMenu')}</span>
                <XMarkIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>

            {/* Links */}
            <div className="flex flex-col">
              {nav1?.map((item, i) => {
                const { title, items, featuredItems } = item as NavigationItem
                return (
                  <Disclosure key={i}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={cn(
                            // state.menuOpen == url.id
                            //   ? 'text-primary border-primary'
                            //   : 'text-primary-2 border-transparent',
                            i % 2 !== 0 && 'border-t-2',
                            'flex items-center justify-between text-left text-white bg-primary border-white whitespace-nowrap py-4 px-6 border-b-2_ text-base font-bold cursor-pointer',
                          )}
                          // onClick={() => {
                          //   return state.setMenu(navigation.slug, url.id)
                          // }}
                        >
                          {title}
                          {open ? (
                            <ChevronUpIcon className="h-4" />
                          ) : (
                            <ChevronDownIcon className="h-4" />
                          )}
                        </Disclosure.Button>

                        <Disclosure.Panel>
                          <div className="">
                            {/* {items?.map((subItem) => {
                        const { title, featuredItems, url, items } = subItem as
                          | NavigationItem
                          | NavigationCategoryItem

                        // if (url.id !== state.menuOpen) return null

                        return ( */}
                            <div key={title} className="">
                              <div className="grid grid-cols-1 gap-x-4">
                                {featuredItems?.map((feature) => (
                                  <MainNavigationFeature
                                    key={feature.id}
                                    {...feature}
                                  />
                                ))}
                              </div>
                              <div className="flex flex-col items-start px-6 pt-6 pb-8 gap-y-3 bg-primary">
                                {items?.map((childItem, index) => {
                                  if (childItem?.items?.length) {
                                    return (
                                      <Disclosure key={index}>
                                        <>
                                          <div
                                            onClick={() =>
                                              handleDisclosureButton(
                                                childItem.url.id,
                                              )
                                            }
                                            className={
                                              openDisclosure ===
                                              childItem.url.id
                                                ? css.mobileSubCategoryButtonWrapperOpen
                                                : css.mobileSubCategoryButtonWrapperClosed
                                            }
                                          >
                                            <Disclosure.Button
                                              className={
                                                openDisclosure ===
                                                childItem.url.id
                                                  ? css.mobileSubCategoryButtonOpen
                                                  : css.mobileSubCategoryButtonClosed
                                              }
                                            >
                                              {childItem.title}
                                            </Disclosure.Button>
                                            {openDisclosure ===
                                            childItem.url.id ? (
                                              <ChevronUpIcon
                                                className={
                                                  css.mobileSubCategoryButtonIcon
                                                }
                                              />
                                            ) : (
                                              <ChevronDownIcon
                                                className={
                                                  css.mobileSubCategoryButtonIcon
                                                }
                                              />
                                            )}
                                          </div>
                                          <Reveal
                                            isOpen={
                                              openDisclosure ===
                                              childItem.url.id
                                            }
                                            className="transition duration-500 ease-out"
                                            closedClassName="opacity-0 -translate-x-1"
                                            skipOutTransition
                                            openClassName="opacity-100 translate-x-0"
                                          >
                                            <Disclosure.Panel
                                              static
                                              as="div"
                                              className={
                                                css.mobileSubCategoryPanel
                                              }
                                            >
                                              {childItem.items.map(
                                                (item, index) =>
                                                  item.url.src ? (
                                                    <Link
                                                      href={item.url.src}
                                                      onClick={close}
                                                      key={index}
                                                      className={
                                                        css.mobileSubCategoryPanelLink
                                                      }
                                                    >
                                                      {item.title}
                                                    </Link>
                                                  ) : null,
                                              )}
                                            </Disclosure.Panel>
                                          </Reveal>
                                        </>
                                      </Disclosure>
                                    )
                                  }

                                  if (!childItem.url.src) return null

                                  return (
                                    <div
                                      key={index}
                                      className="flex items-center"
                                    >
                                      {childItem.highlight && (
                                        <ArrowRightIcon className="w-5 h-5 mr-1 translate-x-[-2px] text-primary-2" />
                                      )}
                                      <Link
                                        href={childItem.url.src}
                                        onClick={close}
                                        className={
                                          childItem.highlight
                                            ? css.mobileSubCategoryLinkHighlight
                                            : css.mobileSubCategoryLink
                                        }
                                      >
                                        {childItem.title}
                                      </Link>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* )
                      })} */}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              })}
            </div>

            <div className="px-6 py-6 space-y-3 border-t_ border-primary_">
              {nav2?.map((item) => {
                const { title, url } = item as NavigationItem

                if (!url.src) return null

                return (
                  <div key={title}>
                    <Link
                      href={url.src}
                      className="inline-block p-2 -m-2 font-bold text-primary"
                    >
                      {title}
                    </Link>
                  </div>
                )
              })}
            </div>

            <div className="px-6 py-6 space-y-3 border-t-2 border-b-2 border-primary">
              <div>
                <Link
                  href="/contact"
                  className="flex items-center p-2 -m-2 font-bold text-primary"
                >
                  <EnvelopeIcon className="w-5 h-5 mr-2" />
                  <span>{translate('contactUs')}</span>
                </Link>
              </div>
              <div>
                <Link
                  href="/service"
                  className="flex items-center p-2 -m-2 font-bold text-primary"
                >
                  <LifebuoyIcon className="w-5 h-5 mr-2" />
                  <span>{translate('serviceParts')}</span>
                </Link>
              </div>
              <div>
                <Link
                  href="/customer"
                  className="flex items-center p-2 -m-2 font-bold text-primary"
                >
                  <UserIcon className="w-5 h-5 mr-2" />
                  <span>{translate('myAccount')}</span>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="px-6 py-6 space-y-3 border-b-2 border-neutral-2">
              <SelectLocation colorTheme="Default" />
            </div>
            <div className="px-6 py-6">
              <Logos colorTheme="Default" logos={['iwf', 'ipf', 'wppo']} link />
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default MainNavigationMobile
