import Link from 'components/ui/Link'
import React, { useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface Props {
  children: string | null
  className?: string
}

const RichText = ({ className, children }: Props) => {
  const t = useMemo(() => children?.replace(/<br\s*\/?>/g, '  \n'), [children])

  if (!t) return null
  return (
    <div className={className}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ children, href }) => {
            return <Link href={href!}>{children}</Link>
          },
        }}
      >
        {t}
      </ReactMarkdown>
    </div>
  )
}

export default RichText
