import cn from 'classnames'
import { CommonSectionSettingsContainerEnum } from 'framework/strapi/types'
import React, { FC } from 'react'

export type ContainerLayout = CommonSectionSettingsContainerEnum | 'FluidRight'
interface Props {
  children?: React.ReactNode
  layout?: ContainerLayout
  direction?: ContainerDirectionEnum
  align?: ContainerAlignEnum
  overflow?: ContainerOverflowEnum
  height?: ContainerHeightEnum
}

type ContainerDirectionEnum = 'Row' | 'Col'
type ContainerAlignEnum = 'Start' | 'Center' | 'End'
type ContainerOverflowEnum = 'Default' | 'XHidden'
type ContainerHeightEnum = 'Default' | 'Screen' | 'Md' | 'Grow'

const containerMap: {
  // eslint-disable-next-line no-unused-vars
  [layout in ContainerLayout]: string
} = {
  FullFluid: 'relative w-full flex md:px-14 lg:px-20',
  FluidFull: 'relative w-full flex px-6 md:px-0',
  Fluid: 'relative w-full flex px-6 md:px-14 lg:px-20',
  FluidRight: 'relative w-full flex px-6 md:px-14 lg:pl-0 lg:pr-20',
  Constrained: 'relative w-full flex px-6 md:px-14 lg:px-20 max-w-7xl mx-auto',
  Prose: 'relative w-full flex px-6 md:px-14 lg:px-20 max-w-4xl  mx-auto',
  Default: 'relative w-full flex',
  Full: 'relative w-full flex',
}

const Container: FC<Props> = ({
  layout,
  direction,
  height,
  align,
  overflow,
  children,
}) => {
  const css = {
    container: !layout ? '' : containerMap[layout],
  }

  // Direction - Default = Column
  css.container =
    !direction || direction === 'Col'
      ? cn(css.container, 'flex-col')
      : css.container

  // Height
  if (height === 'Grow') {
    css.container = cn(css.container, 'flex-1')
  }

  // Align
  if (align === 'Start') {
    css.container =
      !direction || direction === 'Col'
        ? cn(css.container, 'justify-start')
        : cn(css.container, 'items-start')
  }
  if (align === 'Center') {
    css.container =
      !direction || direction === 'Col'
        ? cn(css.container, 'justify-center')
        : cn(css.container, 'items-center')
  }
  if (align === 'End') {
    css.container =
      !direction || direction === 'Col'
        ? cn(css.container, 'justify-end')
        : cn(css.container, 'items-end')
  }

  // Overflow
  if (overflow === 'XHidden') {
    css.container = cn(css.container, 'overflow-x-hidden')
  }

  if (layout === 'Constrained' || layout === 'Prose') {
    return (
      <div className="flex flex-1">
        <div className={css.container}>{children}</div>
      </div>
    )
  }

  return <div className={css.container}>{children}</div>
}

export default Container
