export const getLanguageFromChannel = (channel?: string) => {
  if (!channel) return 'en'
  if (channel.indexOf('-')) {
    return channel.split('-')[0]
  }
  return channel
}

export const getLocaleOrDefault = (locale?: string) => locale || 'en'
export const isGlobalChannel = (channel: string) => {
  return channel?.length == 2
}

const channelPrefixRegex = /^[a-z]{2}-[a-z]{2}$|^[a-z]{2}$/gi
export const isChannelPrefix = (s: string) => channelPrefixRegex.test(s)

export const localeNotFound = (locale?: string) => !locale || locale === '--'
