import React from 'react'
import { CommonBackground } from 'framework/strapi/types'
import BackgroundLayer from './BackgroundLayer'
import BackgroundCaption from './BackgroundCaption'
import { isComponentPublishedInChannel } from 'utils/isInChannel'
import { useAppSelector } from 'store'

interface Props extends CommonBackground {
  sizes?: string
}

const Background = ({
  captionText,
  tw_captionPlacement,
  layout,
  layers,
  sizes,
}: Props) => {
  const locale = useAppSelector((s) => s.channel.locale)
  if (!layers?.length) return null
  // CSS
  const css = {
    wrapper: 'absolute inset-0 flex -z-100',
  }

  if (layout === 'Stacked') {
    css.wrapper = 'absolute inset-0 flex flex-col -z-100'
  }

  return (
    <>
      <div className={css.wrapper}>
        {layers.map((layer, index) => {
          if (!isComponentPublishedInChannel(locale, layer)) return null
          return (
            <BackgroundLayer
              key={index}
              {...layer}
              sizes={sizes}
              layout={layout}
            />
          )
        })}
      </div>

      {/* Caption */}
      <BackgroundCaption
        captionText={captionText}
        tw_captionPlacement={tw_captionPlacement}
      />
    </>
  )
}

export default Background
