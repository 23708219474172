import { Bars3BottomLeftIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import React, { FC } from 'react'
import translate from 'utils/translate'

interface Props {
  colorTheme: CommonSectionSettingsColorThemeEnum
  onClick: () => any
}

const MenuIcon: FC<Props> = ({ colorTheme, onClick }) => {
  return (
    <div className="flex items-center flex-shrink mr-2 lg:hidden">
      <button
        type="button"
        className={cn(
          'ml-[-12px] p-[12px]',
          colorTheme === 'Dark' ? 'text-white' : 'text-primary',
        )}
        onClick={onClick}
      >
        <span className="sr-only">{translate('openMenu')}</span>
        <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
      </button>
    </div>
  )
}

export default MenuIcon
