import Cookies from 'js-cookie'

export const getOrClearCookie = <T>(name: string) => {
  const cookieString = Cookies.get(name)
  if (!cookieString) return null
  try {
    return JSON.parse(cookieString) as T
  } catch (error) {
    console.error(`Could not parse cookie: ${name} to JSON`, cookieString)
    Cookies.remove(name)
    return null
  }
}
