import { NavigationChildItem } from 'framework/strapi/types'
import { useMemo } from 'react'

/**
 * Creates 2 columns if number of items are 10 or more
 * @param items
 * @returns
 */
const useColumns = (items: NavigationChildItem[]) => {
  return useMemo(() => {
    if (items.length < 10) return [items]
    return items.reduce<NavigationChildItem[][]>(
      (a, item, i) => {
        a[i % 2]?.push(item)
        return a
      },
      [[], []],
    )
  }, [items])
}

export default useColumns
