import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dictionary } from 'utils/dictionary'

interface State {
  locale: string
  country: string | null
  region: string | null
  language: string
  showPrices: boolean
  currency: string
  forbiddenCountries: Dictionary<boolean>
  userInfo: UserInfo
}

interface UserInfo {
  region: string | null
  country: string | null
}

const initialState: State = {
  locale: 'en',
  country: null,
  region: null,
  language: 'en-us',
  currency: 'usd',
  showPrices: false,
  forbiddenCountries: {},
  userInfo: {
    country: 'us',
    region: 'na',
  },
}

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setLocale: (state, a: PayloadAction<string>) => {
      state.locale = a.payload
    },
    setRegion: (state, a: PayloadAction<string>) => {
      state.region = a.payload
    },
    setCountry: (state, a: PayloadAction<string>) => {
      state.country = a.payload
    },
    setLanguage: (state, a: PayloadAction<string>) => {
      state.language = a.payload
    },
  },
})

export default channelSlice.reducer
