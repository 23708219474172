import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { navigationZIndex } from 'components/common/MainNavigation'
import classNames from 'classnames'
import Reveal from 'components/common/Reveal'

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean
  zIndex?: string
  onClose?: () => any
}
const Overlay: FC<Props> = ({
  isOpen,
  onClose,
  zIndex,
  id,
  className,
  ref,
  ...rest
}) => {
  return (
    <Reveal
      isOpen={isOpen}
      className={classNames(
        'fixed inset-0 transition-colors duration-500',
        zIndex ?? navigationZIndex.overlay,
        className,
      )}
      openClassName="bg-primary-dark/50"
      closedClassName="bg-primary-dark/0"
      onClick={onClose}
      {...rest}
    />
  )
}

export default Overlay
