import { ArrowRightIcon } from '@heroicons/react/24/solid'
import Link from 'components/ui/Link'
import { NavigationFeaturedItem } from 'framework/strapi/types'
import React, { FC } from 'react'
import CmsImage from '../../CmsImage'

interface Props extends NavigationFeaturedItem {}

const MainNavigationFeature: FC<Props> = ({
  heading,
  image,
  ctas,
  tw_overlay,
}) => {
  return (
    <div
      key={heading}
      className="relative overflow-hidden aspect-w-3 aspect-h-2 bg-primary"
    >
      <CmsImage
        size="5x4"
        image={image}
        fill
        className="object-cover object-center"
      />
      <div className={tw_overlay || ''}></div>
      <div className="flex flex-col justify-end p-6">
        <h3 className="block text-lg font-bold text-white">{heading}</h3>
        <div className="flex flex-wrap mt-1 -mx-2 -mb-1 gap-x-1">
          {ctas?.map((cta, index) =>
            cta.url.src ? (
              <Link
                key={index}
                href={cta.url.src}
                aria-hidden="true"
                className="inline-flex items-center px-2 py-1 text-sm font-bold text-white outline-none"
              >
                {cta.title}
                <ArrowRightIcon className="w-4 h-4 ml-2" />
              </Link>
            ) : null,
          )}
        </div>
      </div>
    </div>
  )
}

export default MainNavigationFeature
