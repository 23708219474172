import 'styles/globals.css'
import 'styles/chrome-bug.css'
import '@total-typescript/ts-reset'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import createStore, { StateType } from '../store'
import { Provider } from 'react-redux'
import React, { FC, useEffect, useMemo } from 'react'
import type { AppProps } from 'next/app'
import Head from 'components/common/Head'
import { useRouter } from 'next/router'
import Layout from 'components/common/Layout'
import useUIStore, {
  initialState as uiInitialState,
  UIStore as UIState,
  forceCountrySelector,
} from 'store/ui'
import { getLocaleOrDefault, isGlobalChannel } from 'utils/localization'
import { setTranslations } from 'utils/translate'
import { getOrClearCookie } from 'utils/cookies'
import {
  CountryCheckCookie,
  COUNTRYCHECK_COOKIENAME,
  UserInfoCookie,
  USERINFO_COOKIENAME,
  UserSettingsCookie,
  USERSETTINGS_COOKIENAME,
} from 'framework/next/cookieNames'
import shouldDebugAnalytics from 'utils/shouldDebugAnalytics'
import { InitGoogleTagManager } from 'framework/tracking/GoogleTagManager/Tag'
import { TrackPageView, TrackPageError } from 'framework/tracking/common/events'
import { LayoutProps } from 'framework/common/server/getLayoutProps'
import { Dictionary } from 'utils/dictionary'
import dynamic from 'next/dynamic'
import { Channel } from 'framework/strapi/types'
import getErrorMessage from 'utils/getErrorMessage'

const GoogleTagManagerTag = dynamic(
  () => import('framework/tracking/GoogleTagManager/Tag'),
  { ssr: false },
)

const getInitialState = (
  locale: string,
  channel: Channel | null,
  forbiddenCountries: Dictionary<boolean> = {},
): Partial<StateType & { ui: UIState }> => {
  let userInfo = getOrClearCookie<UserInfoCookie>(USERINFO_COOKIENAME)
  const userSettings = getOrClearCookie<UserSettingsCookie>(
    USERSETTINGS_COOKIENAME,
  )
  const countryCheck = getOrClearCookie<CountryCheckCookie>(
    COUNTRYCHECK_COOKIENAME,
  )

  // User info is not set during build time so we just set it to default
  // when building the site
  if (!userInfo) {
    userInfo = {
      region: 'na',
      country: 'us',
    }
  }

  if (!channel) {
    channel = {
      id: -1,
      country: 'us',
      currency: 'usd',
      code: 'en-us',
      language: 'en',
      region: 'na',
      isDefault: true,
    }
  }

  if (isGlobalChannel(locale)) {
    const country = userSettings?.country || null
    return {
      ui: !userSettings
        ? {
            ...uiInitialState,
            overlay: 'local',
            hideScroll: true,
            isOpen: true,
          }
        : undefined,
      channel: {
        country,
        region: userSettings?.region?.toLowerCase() || null,
        currency: 'usd',
        language: locale,
        locale,
        showPrices: !(
          forbiddenCountries[userInfo.country!] || forbiddenCountries[country!]
        ),
        forbiddenCountries,
        userInfo,
      },
    }
  }

  let p = locale.split('-')
  if (p.length !== 2) {
    console.error('Failed to parse language or country from channel', locale)
    p = ['en', 'us']
  }
  const [language, country] = p
  const currency = channel?.currency || 'usd'

  let ui: UIState | undefined = undefined
  if (userInfo?.country !== country) {
    if (forceCountrySelector.includes(locale)) {
      ui = {
        ...uiInitialState,
        overlay: 'local',
        hideScroll: true,
        isOpen: true,
      }
    } else if (countryCheck?.closed !== true) {
      ui = {
        ...uiInitialState,
        overlay: 'countryNotification',
        isOpen: true,
      }
    }
  }

  return {
    ui,
    channel: {
      country,
      language,
      region: channel?.region?.toLowerCase() || null,
      currency,
      locale,
      showPrices: !(
        forbiddenCountries[userInfo.country!] || forbiddenCountries[country!]
      ),
      forbiddenCountries,
      userInfo,
    },
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  const r = useRouter()
  const ui = useUIStore()
  const locale = getLocaleOrDefault(r.locale)
  const props = pageProps as LayoutProps

  if (!props.channel) {
    console.error(`Channel is not set for pageProps: ${JSON.stringify(props)}`)
  }

  const { ui: uiState, channel } = getInitialState(
    locale,
    props.channel,
    props.forbiddenCountries,
  )
  useEffect(() => {
    InitGoogleTagManager(props.channel)

    // Load the overlay if it's set
    if (uiState?.isOpen && uiState.overlay) {
      ui.open(uiState.overlay, uiState.hideScroll)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  props.translations && setTranslations(props.translations)

  return (
    <>
      <GoogleTagManagerTag id={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER} />
      <Head />
      <Provider store={createStore({ channel })}>
        <AppShell>
          <Layout pageProps={pageProps}>
            <Component {...pageProps} />
          </Layout>
        </AppShell>
      </Provider>
    </>
  )
}

const AppShell: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const r = useRouter()
  const ui = useUIStore()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 3,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            console.error(error)
            ui.showToast(getErrorMessage(error))
          },
        }),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    document.body.classList?.remove('loading')
    const onRouteChangeStart = () => {
      // console.log('onRouteChangeStart')
      ui.setLoading(true)
      ui.close()
    }
    const onRouteChangeComplete = (url: string) => {
      if (window['HubSpotConversations']) {
        window['HubSpotConversations']?.resetAndReloadWidget()
      }
      TrackPageView({
        url,
        debug: shouldDebugAnalytics(r),
      })
      // console.log('ounRouteChangeComplete')
      ui.setLoading(false)
    }
    const onRouteChangeError = (error: { cancelled: boolean }, url: string) => {
      if (!error.cancelled) {
        TrackPageError({
          error,
          url,
          debug: shouldDebugAnalytics(r),
        })
      }
      ui.setLoading(false)
    }
    r.events.on('routeChangeStart', onRouteChangeStart)
    r.events.on('routeChangeComplete', onRouteChangeComplete)
    r.events.on('routeChangeError', onRouteChangeError)
    return () => {
      r.events.off('routeChangeStart', onRouteChangeStart)
      r.events.off('routeChangeComplete', onRouteChangeComplete)
      r.events.off('routeChangeError', onRouteChangeError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ui.isOpen && ui.hideScroll) {
      document.body.className = 'overflow-y-hidden'
    } else {
      document.body.className = ''
    }
  }, [ui.isOpen, ui.hideScroll])

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
export default App
