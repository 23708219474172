import cn from 'classnames'
import Overlay from 'components/ui/Overlay'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { navigationZIndex } from '../MainNavigation'
import Reveal from '../Reveal'

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean
  onClose?: () => any
  maxWidth?: string
}

const Modal: FC<Props> = ({
  isOpen,
  children,
  onClose,
  className,
  maxWidth = 'max-w-xl',
}) => {
  return (
    <Overlay
      isOpen={isOpen}
      onClose={onClose}
      className={cn('flex justify-center items-center', className)}
      zIndex={navigationZIndex.drawerOverlay}
    >
      <Reveal
        className={cn(
          'transition-opacity duration-200 ease-in-out w-full bg-white shadow-xl flex flex-col items-stretch',
          maxWidth,
          navigationZIndex.drawer,
        )}
        role="dialog"
        aria-modal
        openClassName="opacity-100"
        closedClassName="opacity-0"
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Reveal>
    </Overlay>
  )
}

export default Modal
