import {
  NavigationCategoryItemColorThemeEnum,
  NavigationItem,
  StrapiComponent,
} from 'framework/strapi/types'
import React, { FC, MouseEvent } from 'react'
import cn from 'classnames'
import Link from 'components/ui/Link'
import { NavigationState } from '../useNavigation'
import MegaMenu from './MegaMenu'
import triggerMouseNavigation from 'utils/triggerMouseNavigation'

interface Props {
  colorTheme?: NavigationCategoryItemColorThemeEnum
  state: NavigationState
  navigationId: string
  item: StrapiComponent
}

const NavigationLink: FC<Props> = ({
  colorTheme,
  item,
  state,
  navigationId,
}) => {
  const css = {
    categoryButton:
      'outline-none h-full flex items-center px-3 transition-all duration-200 font-bold text-[0.938rem] border-b-4 border-t-4 border-t-transparent border-b-transparent',
    categoryButtonOpen:
      'outline-none h-full flex items-center px-3 transition-all duration-200 font-bold text-[0.938rem] border-b-4 border-t-4 border-t-transparent',
    categoryButtonWrapper: 'h-full',
  }

  switch (colorTheme) {
    case 'Dark':
      css.categoryButton = cn(
        css.categoryButton,
        'hover:border-b-white text-white',
      )
      css.categoryButtonOpen = cn(
        css.categoryButtonOpen,
        'border-b-white text-white',
      )
      break
    default:
      css.categoryButton = cn(
        css.categoryButton,
        'hover:border-b-primary text-primary',
      )
      css.categoryButtonOpen = cn(
        css.categoryButtonOpen,
        'border-b-primary text-primary',
      )
  }

  const { items, title, url } = item as NavigationItem
  const isOpen =
    state.navigationOpen === navigationId && state.menuOpen === url.id
  const close = () => state.setMenu()
  const open = () => state.setMenu(navigationId, url.id)

  const toggle = () => {
    isOpen ? close() : open()
  }

  if (items?.length) {
    return (
      <div className={css.categoryButtonWrapper}>
        <>
          <button
            className={cn(css.categoryButton)}
            onClick={toggle}
            onMouseMove={(e) => {
              if (triggerMouseNavigation(e)) {
                open()
              }
            }}
          >
            {title}
          </button>
          <MegaMenu
            logos={[]}
            state={state}
            navigationId={navigationId}
            item={item as NavigationItem}
          />
        </>
      </div>
    )
  }

  if (!url.src) {
    return null
  }

  return (
    <div className={css.categoryButtonWrapper}>
      <Link
        onMouseMove={(e: MouseEvent<HTMLAnchorElement>) => {
          if (triggerMouseNavigation(e)) {
            close()
          }
        }}
        className={css.categoryButton}
        href={url.src}
      >
        {title}
      </Link>
    </div>
  )
}

export default NavigationLink
