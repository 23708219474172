import { FC } from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { getLocaleOrDefault } from 'utils/localization'
import translate from 'utils/translate'

interface SEOProps {
  title: string
  titleSuffix?: string
  desc?: string
  image?: string
  tags?: string[]
  canonicalUrl?: string | null
}
const siteName = 'https://eleiko.com'

const Head: FC = () => {
  const router = useRouter()
  const locale = getLocaleOrDefault(router.locale)
  const seoTitle = translate('seo_title')
  const seoDesc = translate('seo_description')
  const keywords = translate('seo_keywords')
  const seoTags = keywords.split(',').map((tag) => tag.trim())
  return (
    <>
      <NextHead>
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteName} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          property="og:url"
          content={`${siteName}/${locale}${router.asPath}`}
        />
      </NextHead>
      <SEOHeader
        {...{
          title: seoTitle,
          desc: seoDesc,
          tags: seoTags,
          titleSuffix: '',
          image: 'https://eleiko.com/card.webp',
        }}
      />
    </>
  )
}

export const SEOHeader: FC<SEOProps> = ({
  title,
  desc,
  image,
  titleSuffix = ' | Eleiko',
  tags,
  canonicalUrl,
}) => {
  const fullTitle = title.trim() + titleSuffix
  return (
    <NextHead>
      <title key="title">{fullTitle}</title>
      <meta property="og:title" key="og:title" content={fullTitle} />
      {!!desc && (
        <>
          <meta name="description" key="description" content={desc} />
          <meta property="og:description" key="og:description" content={desc} />
        </>
      )}
      {!!image && <meta property="og:image" key="og:image" content={image} />}
      {!!tags?.length && <meta name="keywords" content={tags.join(',')} />}
      {!!canonicalUrl?.length && <link rel="canonical" href={canonicalUrl} />}
    </NextHead>
  )
}
export default Head
