import { ModelStateError, WarningError } from './handleResponse'
import translate, { TranslationKeys } from './translate'
import { ToastProps } from 'store/ui'

const getErrorMessage = (e: unknown): ToastProps => {
  if (e instanceof WarningError) {
    return { type: 'warning', message: e.message }
  }
  if (e instanceof ModelStateError && e.modelState.modelState) {
    if (e.modelState.modelState) {
      // Get the first error from Modelstate
      let m = Object.values(e.modelState.modelState)[0]?.[0]
      if (m) {
        m = translate(m as TranslationKeys)
        if (m) {
          return { type: 'error', message: m }
        }
      }
    } else if (e.modelState.message) {
      const m = translate(e.modelState.message as TranslationKeys)
      if (m) {
        return { type: 'error', message: m }
      }
    }
  }
  if (e && typeof e === 'object' && 'message' in e) {
    const m = translate(e.message as TranslationKeys)
    if (m) {
      return { type: 'error', message: m }
    }
  }
  return { type: 'error', message: translate('generalError') }
}

export default getErrorMessage
