import cn from 'classnames'
import Background from 'components/common/Background'
import Container from 'components/ui/Container'
import { NavigationItem } from 'framework/strapi/types'
import React, { FC } from 'react'
import MainNavigationFeatured from '../MainNavigationFeatured'
import { NavigationState } from '../useNavigation'
import MegaMenuCategories from './MegaMenuCategories'
import MegaMenuPages from './MegaMenuPages'
import Logos from 'components/common/Logos'
import Reveal from 'components/common/Reveal'

interface Props {
  state: NavigationState
  navigationId: string
  item: NavigationItem
  logos: string[]
}

const MegaMenu: FC<Props> = (props) => {
  const { item, state, navigationId } = props
  // Css: Panel Category
  const css = {
    navigationPanel:
      'absolute bottom-0 left-0 w-full translate-y-full transition-opacity duration-500 ease-out',
    navigationPanelGrid: 'grid lg:grid-cols-2',
    subCategoriesCol: 'pt-16 flex flex-col justify-between',
    subCategoriesColGrid: 'grid grid-cols-2 gap-x-6',
  }

  switch (item.colorTheme) {
    case 'Dark':
      css.navigationPanel = cn(css.navigationPanel, 'bg-primary')
      break
    default:
      css.navigationPanel = cn(css.navigationPanel, 'bg-neutral-2')
  }

  const isOpen =
    state.open &&
    state.navigationOpen === navigationId &&
    state.menuOpen === item.url.id

  return (
    <Reveal
      isOpen={isOpen}
      className={css.navigationPanel}
      openClassName="opacity-100"
      closedClassName="opacity-0"
    >
      {item.background && <Background {...item.background} />}
      <Container>
        <div className={css.navigationPanelGrid}>
          <div className={css.subCategoriesCol}>
            <Container layout="Fluid">
              <div className="flex flex-col justify-between flex-1">
                <div className={css.subCategoriesColGrid}>
                  {item.layout !== 'Secondary' ? (
                    <MegaMenuCategories {...props} />
                  ) : (
                    <MegaMenuPages {...props} />
                  )}
                </div>
              </div>
            </Container>
            <div className="relative pb-10 mt-16">
              <Container layout="Fluid">
                <Logos colorTheme="Dark" logos={['iwf', 'ipf', 'wppo']} link />
              </Container>
            </div>
          </div>
          {item.featuredItems && (
            <MainNavigationFeatured items={item.featuredItems} />
          )}
        </div>
      </Container>
    </Reveal>
  )
}

export default MegaMenu
