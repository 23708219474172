import { ID } from 'framework/common/types'
import { useMemo, useCallback } from 'react'
import useUIStore from 'store/ui'

export interface NavigationState {
  open: boolean
  navigationOpen: ID | null
  menuOpen: ID | null
  submenuOpen: ID | null
  setMenu: (
    navigation?: string | undefined,
    menu?: ID | undefined,
    submenu?: ID | undefined,
  ) => void
}

/**
 * Helper hooks for setting the navigation state
 * navigationOpen is the currently open navigation (if any)
 * menuOpen is the menu within that navigation that is open (if any)
 * subMenuOpen is the child menu within the open menu (if any)
 *
 * The navigation is stored in the state like (navigationId/menuId/submenuId)
 *
 * Example (main-navigation/Equipment/Bars)
 */
const useNavigation = (): NavigationState => {
  const ui = useUIStore()
  const [open, navigationOpen, menuOpen, submenuOpen] = useMemo(() => {
    if (!ui.overlay) return [ui.isOpen, null, null, null]

    const [n, m, s] = ui.overlay.split('/')
    return [ui.isOpen, n || null, m || null, s || null]
  }, [ui.isOpen, ui.overlay])

  const setMenu = useCallback(
    (navigation?: ID, menu?: ID, submenu?: ID) => {
      if (!navigation || !menu) ui.toggleMenu(null)
      else if (!submenu) ui.toggleMenu(`${navigation}/${menu}`)
      else ui.toggleMenu(`${navigation}/${menu}/${submenu}`)
    },
    [ui],
  )
  return { open, navigationOpen, menuOpen, submenuOpen, setMenu }
}

export default useNavigation
