import { MouseEvent } from 'react'

const triggerMouseNavigation = (e: MouseEvent<any>) => {
  return (
    !window.matchMedia('(pointer: coarse)').matches &&
    (e.movementX === 0 || e.movementY === 0)
  )
}

export default triggerMouseNavigation
