import Link from 'components/ui/Link'
import cn from 'classnames'
import React, { FC } from 'react'
import { NavigationState } from '../useNavigation'
import { NavigationItem } from 'framework/strapi/types'

interface Props {
  state: NavigationState
  navigationId: string
  item: NavigationItem
}

const MegaMenuPages: FC<Props> = ({ item }) => {
  const css = {
    subCategoriesWrapper: 'flex flex-col gap-y-2 items-start',
    subCategoryButtonClosed:
      'outline-none inline-block font-bold 2xl:text-xl text-left border-b-2',
    subCategoryPanelLink: 'block my-0.5 font-bold text-[15px] 2xl:text-lg',
  }

  switch (item.colorTheme) {
    case 'Dark':
      css.subCategoryButtonClosed = cn(
        css.subCategoryButtonClosed,
        'border-white text-white',
      )
      css.subCategoryPanelLink = cn(css.subCategoryPanelLink, 'text-white')
      break
    default:
      css.subCategoryButtonClosed = cn(
        css.subCategoryButtonClosed,
        'border-primary text-primary',
      )
      css.subCategoryPanelLink = cn(css.subCategoryPanelLink, 'text-primary')
  }

  return (
    <>
      {item.items?.map((i) => (
        <div key={i.id} className={css.subCategoriesWrapper}>
          <Link className={css.subCategoryButtonClosed} href={i.url.src || '#'}>
            {i.title}
          </Link>
          {i.items && (
            <div>
              {i.items.map((ii) =>
                ii.url.src ? (
                  <Link
                    key={ii.id}
                    className={css.subCategoryPanelLink}
                    href={ii.url.src}
                  >
                    {ii.title}
                  </Link>
                ) : null,
              )}
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default MegaMenuPages
