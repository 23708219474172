import classNames from 'classnames'
import { FC, SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Spinner: FC<Props> = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      className={classNames('animate-spin', className)}
      {...rest}
    >
      <circle
        cx={100}
        cy={100}
        r={70}
        fill="none"
        stroke="currentColor"
        strokeWidth={20}
        strokeMiterlimit={8}
        strokeDasharray="329.8672,113.9557"
      />
    </svg>
  )
}

export default Spinner
