import cn from 'classnames'
import Container from 'components/ui/Container'
import {
  Navigation,
  NavigationCategoryItemColorThemeEnum,
  NavigationChildItem,
  NavigationItem,
} from 'framework/strapi/types'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Desktop from './Desktop'
import MainNavigationLogo from './MainNavigationLogo'
import MenuIcon from './MenuIcon'
import TopNavigation from './TopNavigation'
import useNavigation from './useNavigation'
import Overlay from 'components/ui/Overlay'
import dynamic from 'next/dynamic'
import Mobile from './Mobile'
import isInChannel from 'utils/isInChannel'
import { useAppSelector } from 'store'

interface Props {
  floating?: boolean
  navigationColorTheme: NavigationCategoryItemColorThemeEnum
  navigation: Navigation
  hideNavigation?: boolean
  secondaryNavigation?: Navigation
  topBanner?: Navigation
  isCheckout: boolean
}

export const navigationZIndex = {
  overlay: 'z-50 lg:z-20',
  topBar: 'z-30',
  nav: 'z-40',
  clickHandler: 'z-50',
  drawerOverlay: 'z-80',
  drawer: 'z-90',
}

// Css
const css = {
  navigationPlaceholder: 'w-full h-16 lg:h-24',
  navigationBase: `flex h-16`,
  navigationTransition: `transition duration-200 ease-out`,
  navigationTransitionColor: `transition-colors duration-200 ease-out`,
  navigationStatic: `lg:h-24`,
  navigationFixed: `lg:h-18 xl:h-20 fixed top-0 left-0 w-full`,
  navigationDown: 'translate-y-0 opacity-1',
  navigationUp: '-translate-y-full opacity-0',
  navigationInner: 'flex flex-1 lg:gap-x-10',
  mobileMenuIcon: '-ml-2 p-2',
}

const RightActions = dynamic(() => import('./RightActions'), { ssr: false })

const filterNavigation = (
  navigation: Navigation,
  locale: string,
  country: string | null,
): Navigation => {
  return {
    ...navigation,
    items:
      navigation.items?.reduce<NavigationItem[]>((a, n) => {
        const item = n as NavigationItem
        if (
          isInChannel({
            text: item.channel,
            locale,
            country: country!,
          })
        ) {
          a.push({
            ...item,
            items:
              item.items?.reduce<NavigationChildItem[]>((aa, nn) => {
                if (
                  isInChannel({
                    text: nn.channel,
                    locale,
                    country: country!,
                  })
                ) {
                  aa.push(nn)
                }
                return aa
              }, []) ?? null,
          })
        }
        return a
      }, []) ?? null,
  }
}

const MainNavigation: FC<Props> = ({
  navigation,
  hideNavigation,
  navigationColorTheme,
  topBanner,
  floating,
  secondaryNavigation,
  isCheckout,
}) => {
  const state = useNavigation()
  const { locale, country } = useAppSelector((s) => s.channel)

  const filteredNav = useMemo(
    () => filterNavigation(navigation, locale, country),
    [navigation, locale, country],
  )

  const filteredSecondaryNav = useMemo(
    () =>
      secondaryNavigation
        ? filterNavigation(secondaryNavigation, locale, country)
        : undefined,
    [secondaryNavigation, locale, country],
  )

  // Color Theme
  const [colorTheme, setColorTheme] = useState(navigationColorTheme)
  const [navigationState, setNavigationState] = useState<
    'static' | 'fixedDown' | 'fixedUp'
  >('static')

  const defaultCssNavigation = cn(
    css.navigationStatic,
    css.navigationTransitionColor,
    css.navigationDown,
    floating
      ? null
      : navigationColorTheme === 'Dark'
        ? 'bg-primary'
        : 'bg-white',
  )

  // Navigation Css
  const [cssNavigation, setCssNavigation] = useState(defaultCssNavigation)

  // Reset Color Theme and CSS for new page load
  useEffect(() => {
    setColorTheme(navigationColorTheme)

    setCssNavigation(defaultCssNavigation)
  }, [
    setColorTheme,
    navigationColorTheme,
    setCssNavigation,
    defaultCssNavigation,
  ])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const y = -currPos.y
      const py = -prevPos.y
      if (y < 48) {
        if (navigationState != 'static') {
          setNavigationState('static')
          cssNavigation !== defaultCssNavigation &&
            setCssNavigation(defaultCssNavigation)
          colorTheme !== navigationColorTheme &&
            setColorTheme(navigationColorTheme)
        }
      } else {
        // Scrolling up
        if (y < py) {
          if (navigationState != 'fixedDown' && navigationState != 'static') {
            const style = cn(
              css.navigationFixed,
              css.navigationTransition,
              css.navigationDown,
              'bg-white',
            )
            cssNavigation !== style && setCssNavigation(style)
            colorTheme !== 'Default' && setColorTheme('Default')
            setNavigationState('fixedDown')
          }
        }

        // Scrolling down
        if (y > py && y > 300) {
          if (navigationState != 'fixedUp') {
            // If scrolling down below the fold. Hide the navigation
            const style = cn(
              css.navigationFixed,
              navigationState === 'static' ? null : css.navigationTransition,
              css.navigationUp,
              'bg-white',
            )
            cssNavigation !== style && setCssNavigation(style)
            colorTheme !== 'Default' && setColorTheme('Default')
            state.menuOpen && state.setMenu()
            setNavigationState('fixedUp')
          }
        }
      }
    },
    [state, colorTheme, cssNavigation, navigationState],
  )

  const showTopBanner = !isCheckout && !!topBanner?.items?.length
  return (
    <>
      {showTopBanner && !hideNavigation && (
        <TopNavigation banners={topBanner!.items!} />
      )}
      <Mobile
        state={state}
        colorTheme={colorTheme}
        navigation={filteredNav}
        secondaryNavigation={filteredSecondaryNav}
      />
      {!floating && <div className={css.navigationPlaceholder} />}
      <div
        className={cn(
          'absolute left-0 w-full',
          navigationZIndex.nav,
          showTopBanner && !hideNavigation ? 'top-12' : 'top-0',
        )}
      >
        <div className={cn(css.navigationBase, cssNavigation)}>
          <Container layout="Fluid" height="Grow">
            <div className={css.navigationInner}>
              {/* Mobile menu (lg-) */}
              {filteredNav.items && filteredNav.items.length > 0 && (
                <MenuIcon
                  colorTheme={colorTheme}
                  onClick={() =>
                    state.setMenu(
                      filteredNav.slug,
                      (filteredNav.items![0] as NavigationItem).url.id,
                    )
                  }
                />
              )}
              <MainNavigationLogo colorTheme={colorTheme} />
              {!hideNavigation && (
                <>
                  <Desktop
                    state={state}
                    navigation={filteredNav}
                    colorTheme={colorTheme}
                  />
                  {!isCheckout && (
                    <RightActions
                      state={state}
                      nav={filteredSecondaryNav}
                      colorTheme={colorTheme}
                    />
                  )}
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
      <Overlay
        id="main-navigation-overlay"
        isOpen={!!(state.open && state.menuOpen)}
        onClose={() => state.setMenu()}
        onMouseMove={() => {
          if (window.matchMedia('(min-width: 1024px)').matches) {
            state.setMenu()
          }
        }}
      />
    </>
  )
}

export default MainNavigation
