import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { HTMLAttributeAnchorTarget, MouseEventHandler } from 'react'

interface Props extends NextLinkProps {
  children?: React.ReactNode
  className?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  onMouseEnter?: () => any
  onMouseMove?: MouseEventHandler<HTMLAnchorElement>
  id?: string
  role?: string
  target?: HTMLAttributeAnchorTarget
}

/**
 * Hybrid link that uses next/link for local urls and a normal <a> tag for absolute urls
 */
const Link: React.FC<Props> = ({ href, children, ...props }) => {
  if (typeof href === 'string' && href.startsWith('https://')) {
    return (
      <a href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    )
  }
  return (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  )
}

export default Link
