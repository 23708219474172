import Script from 'next/script'
import { FC } from 'react'
import { initConsent } from 'components/common/CookieConsent'
import { Channel } from 'framework/strapi/types'

// Google Tag Manager
// <!-- Google Tag Manager -->
//   <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//   })(window,document,'script','dataLayer','${id}');</script>
// <!-- End Google Tag Manager -->

// Beginning of Body tag
// <!-- Google Tag Manager (noscript) -->
//     <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
//     height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
// <!-- End Google Tag Manager (noscript) -->

interface Props {
  id?: string
}

export const InitGoogleTagManager = (channel: Channel | null) => {
  window.dataLayer?.push({
    currency: channel?.currency.toUpperCase() ?? 'USD',
  })
}

const Tag: FC<Props> = ({ id }) => {
  if (!id) return null
  window.dataLayer = window.dataLayer || []
  window.gtag = function gtag() {
    window.dataLayer?.push(arguments)
  }
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
  initConsent()

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtm.js?id=${id}`}
        strategy="afterInteractive"
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}

export default Tag
