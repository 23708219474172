import tryJSONParse from './tryJSONParse'
import { z } from 'zod'

export class WarningError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'WarningError'
    Object.setPrototypeOf(this, WarningError.prototype)
  }
}

export class ModelStateError extends Error {
  constructor(
    public modelState: ModelStateErrorContent,
    message?: string,
  ) {
    super(message)
    this.name = 'ModelStateError'
    Object.setPrototypeOf(this, ModelStateError.prototype)
  }
}

export class FetchError extends Error {
  constructor(
    public url: string,
    public status: number,
    public content?: string,
    message?: string,
  ) {
    super(`Fetch error: ${message} ${url} ${status} ${content}`)
    this.name = 'FetchError'
    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

const modelStateContentSchema = z.object({
  modelState: z.record(z.array(z.string())).optional(),
  message: z.string().optional(),
})

export type ModelStateErrorContent = z.infer<typeof modelStateContentSchema>

const handleJSONResponse = async <T>(
  r: Response | null,
  requestUrl: string = '',
): Promise<T> => {
  if (!r)
    throw Error(
      `Request Failed Looks like the url ${requestUrl} is not reachable, if this is a local environment make sure your server is running`,
    )
  const content = await tryGetContent(r)
  if (!r.ok) {
    if (content) {
      // Get Json from content
      const json = tryJSONParse(content)
      // Could not parse JSON, throw error with content
      if (!json) throw Error(`Error fetching url: ${r.url} ${content}`)

      // Try to parse a modelstate Error. These errors occurs during server validation
      const parsedContent = modelStateContentSchema.safeParse(json)
      if (parsedContent.success) {
        throw new ModelStateError(
          parsedContent.data,
          `Error fetching url: ${r.url}`,
        )
      }
    }
    throw new FetchError(r.url, r.status, r.statusText)
  }

  if (r.type === 'opaque') {
    throw Error(
      `Cross origin request without the CORS headers for url: ${r.url}`,
    )
  }

  if (!content) {
    throw Error(`No content for url: ${r.url}`)
  }

  const body = tryJSONParse<T>(content)
  if (!body) throw Error(`Could not parse JSON from url: ${r.url}`)

  return body
}

const tryGetContent = (r: Response) => r.text().catch(() => null)

export default handleJSONResponse
