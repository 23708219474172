import { StrapiFileComponent } from 'framework/strapi/types'

import { z } from 'zod'

const supportedMediaTypes = z.enum([
  'image',
  'video',
  'normalvideo',
  'embed',
  'embed-3d',
])

/**
 * The supported media types that we can render with the CMS Image Component
 * @see {@link components/common/CmsImage/index.tsx}
 */
export type SupportedMediaTypes = z.infer<typeof supportedMediaTypes>

/**
 * Get the media type of a Strapi file component.
 *
 * @param image The image to get the media type of.
 * @param size The size of the image to get the media type of.
 * @returns The media type of the image.
 */
export const getStrapiFileMediaType = (
  image: StrapiFileComponent | undefined,
  size?: string | undefined,
): SupportedMediaTypes | 'unknown' => {
  if (!image) {
    return 'unknown'
  }

  const mime = (image.formats && image.formats[size || '']?.mime) || image.mime
  if (!mime) {
    return 'unknown'
  }

  const [type] = mime.split('/')

  const parsedType = supportedMediaTypes.safeParse(type)

  if (!parsedType.success) {
    return 'unknown'
  }

  return parsedType.data
}
