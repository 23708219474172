import cn from 'classnames'
import CmsImage from 'components/common/CmsImage'
import { CommonBackgroundLayer } from 'framework/strapi/types'
import React, { FC } from 'react'
import { toStrapiFileComponent } from 'utils/canto'

interface Props extends CommonBackgroundLayer {
  sizes?: string
  layout?: string | null
}

const BackgroundLayer: FC<Props> = ({
  image,
  image_canto,
  tw_overlay,
  tw_value,
  sizes,
  layout,
}) => {
  const css = {
    layer: 'relative flex-1 overflow-hidden',
  }
  css.layer = cn(css.layer, {
    'last:even:hidden lg:last:even:block': layout === 'AdaptiveGrid',
  })
  if (image_canto) {
    const cantoImage = toStrapiFileComponent(image_canto)

    return (
      <div className={css.layer}>
        <CmsImage image={cantoImage} fill sizes={sizes} className="object-cover" />
        {tw_overlay && (
          <div className={cn('absolute inset-0', tw_overlay)}></div>
        )}
      </div>
    )
  }

  if (image && !image_canto) {
    return (
      <div className={css.layer}>
        <CmsImage image={image} fill sizes={sizes} className="object-cover" />
        {tw_overlay && (
          <div className={cn('absolute inset-0', tw_overlay)}></div>
        )}
      </div>
    )
  }
  if (!tw_value) return null

  return tw_value.startsWith('#') ? (
    <div className={css.layer} style={{ backgroundColor: tw_value }}></div>
  ) : (
    <div className={cn(css.layer, tw_value)}></div>
  )
}

export default BackgroundLayer
