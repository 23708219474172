const PreviewBanner = () => {
  return (
    <div className="px-4 py-2 bg-utility-success-dark md:py-2 md:px-4 lg:py-2 lg:px-8 flex items-center justify-center">
      <div className="items-center justify-center flex">
        <p className="max-w-3xl text-lg leading-6 text-white">
          You are in preview mode -
          <a className="px-2 underline" href={`/api/exit-preview`}>
            Switch off
          </a>
        </p>
      </div>
    </div>
  )
}

export default PreviewBanner
