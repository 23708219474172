import sleep from 'utils/sleep'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export type Overlay =
  | 'upsell'
  | 'local'
  | 'countryNotification'
  | 'cart'
  | 'favorites'
  | 'quotation'
  | 'filter'
  | 'quicksearch'
  | 'newsletter'
  | 'shareList'
  | 'sizechart'
  | 'comparebar'
  | 'productsearchresults'
  | 'carousel'

export interface ToastProps {
  title?: string
  message?: string
  messages?: string[]
  type: 'info' | 'error' | 'warning'
}

export interface UIStore {
  overlay: Overlay | null
  isOpen: boolean
  hideScroll: boolean
  isLoading: boolean
  toast: {
    isOpen: boolean
    toast: ToastProps | null
  }
}

interface UIStoreWithActions extends UIStore {
  showToast: (toast: ToastProps) => Promise<void>
  closeToast: () => void
  toggleMenu: (overlay: string | null) => void
  toggleScroll: (hide: boolean) => void
  open: (overlay: Overlay, hideScrollbar?: boolean) => void
  close: () => void
  setLoading: (loading: boolean) => void
}

export const initialState: UIStore = {
  overlay: null,
  isOpen: false,
  hideScroll: false,
  isLoading: false,
  toast: {
    isOpen: false,
    toast: null,
  },
}

let currentToast: ToastProps | null
const toastDelay = 10000

const store = immer<UIStoreWithActions>((set, get) => ({
  ...initialState,
  showToast: async (toast: ToastProps) => {
    currentToast = toast
    set((s) => {
      s.toast.isOpen = true
      s.toast.toast = toast
    })
    await sleep(toastDelay)
    if (currentToast === toast && get().toast.isOpen) {
      set((s) => {
        s.toast.isOpen = false
      })
    }
  },
  closeToast: () => {
    currentToast = null
    set((s) => {
      s.toast.isOpen = false
    })
  },
  toggleMenu: (overlay: string | null) => {
    const isOpen = !!overlay
    set((s) => {
      s.isOpen = isOpen
      if (isOpen) {
        s.overlay = overlay as Overlay
      }
    })
  },
  toggleScroll: (hide: boolean) => {
    set((s) => {
      s.hideScroll = hide
    })
  },
  open: (overlay: Overlay, hideScrollbar?: boolean) => {
    set((s) => {
      s.isOpen = true
      s.overlay = overlay
      s.hideScroll = hideScrollbar ?? true
    })
  },
  close: () => {
    set((s) => {
      s.isOpen = false
      s.hideScroll = false
    })
  },
  setLoading: (loading: boolean) => {
    set((s) => {
      s.isLoading = loading
    })
  },
}))

const useUIStore = create<UIStoreWithActions>()(
  devtools(store, {
    name: 'eleiko-ui',
    log: true,
    enabled: false,
  }),
)

// For some channels we want to force showing the country selector because
// They are in full english and can be misstaken for the english site.
export const forceCountrySelector = ['en-ax']

export default useUIStore
