import React, { FC } from 'react'
import cn from 'classnames'
import useColumns from './useColumns'
import { Disclosure } from '@headlessui/react'
import { NavigationState } from '../useNavigation'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/solid'
import Link from 'components/ui/Link'
import { NavigationItem } from 'framework/strapi/types'
import Reveal from 'components/common/Reveal'
import { ID } from 'framework/common/types'

interface Props {
  state: NavigationState
  navigationId: string
  item: NavigationItem
}

const MegaMenuCategories: FC<Props> = ({ item, state, navigationId }) => {
  const columns = useColumns(item.items!)

  const css = {
    subCategoriesWrapper: 'flex flex-col gap-y-2 items-start',
    subCategoryButtonWrapperOpen:
      'flex gap-x-2 items-center cursor-pointer border-b-2',
    subCategoryButtonWrapperClosed: 'flex gap-x-2 items-center cursor-pointer',
    subCategoryButtonOpen:
      'outline-none inline-block font-bold 2xl:text-xl text-left border-b-0',
    subCategoryButtonClosed:
      'outline-none inline-block font-bold 2xl:text-xl text-left border-b-2',
    subCategoryButtonIcon: 'h-3',
    subCategoryPanel: 'px-2 pb-2',
    subCategoryPanelLink: 'block my-0.5 font-bold text-[15px] 2xl:text-lg',
    subCategoryLink:
      'inline-block font-bold 2xl:text-xl border-b-2 duration-300',
    subCategoryLinkHighlight:
      'inline-block font-bold 2xl:text-xl border-b-2 group-hover:translate-x-1 duration-300',
  }

  switch (item.colorTheme) {
    case 'Dark':
      css.subCategoryButtonWrapperOpen = cn(
        css.subCategoryButtonWrapperOpen,
        'border-white',
      )
      css.subCategoryButtonOpen = cn(
        css.subCategoryButtonOpen,
        'border-white text-white',
      )
      css.subCategoryButtonClosed = cn(
        css.subCategoryButtonClosed,
        'border-white text-white',
      )
      css.subCategoryButtonIcon = cn(css.subCategoryButtonIcon, 'text-white')
      css.subCategoryPanelLink = cn(css.subCategoryPanelLink, 'text-white')
      css.subCategoryLink = cn(css.subCategoryLink, 'border-white text-white')
      css.subCategoryLinkHighlight = cn(
        css.subCategoryLinkHighlight,
        'border-transparent text-primary-2',
      )
      break
    default:
      css.subCategoryButtonWrapperOpen = cn(
        css.subCategoryButtonWrapperOpen,
        'border-primary',
      )
      css.subCategoryButtonOpen = cn(
        css.subCategoryButtonOpen,
        'border-primary text-primary',
      )
      css.subCategoryButtonClosed = cn(
        css.subCategoryButtonClosed,
        'border-primary text-primary',
      )
      css.subCategoryButtonIcon = cn(css.subCategoryButtonIcon, 'text-primary')
      css.subCategoryPanelLink = cn(css.subCategoryPanelLink, 'text-primary')
      css.subCategoryLink = cn(
        css.subCategoryLink,
        'border-primary text-primary',
      )
      css.subCategoryLinkHighlight = cn(
        css.subCategoryLinkHighlight,
        'border-transparent text-primary-2',
      )
  }

  const toggleSubmenu = (itemId: ID) =>
    state.setMenu(
      navigationId,
      item.url.id,
      state.submenuOpen === itemId ? undefined : itemId,
    )

  return (
    <>
      {columns.map((col, index) => (
        <div key={index} className={css.subCategoriesWrapper}>
          {col.map((section) => {
            if (section.items && section.items.length > 0) {
              return (
                <Disclosure key={section.id}>
                  <>
                    <div
                      onClick={() => toggleSubmenu(section.url.id)}
                      className={
                        state.submenuOpen === section.url.id
                          ? css.subCategoryButtonWrapperOpen
                          : css.subCategoryButtonWrapperClosed
                      }
                    >
                      <Disclosure.Button
                        className={
                          state.submenuOpen === section.url.id
                            ? css.subCategoryButtonOpen
                            : css.subCategoryButtonClosed
                        }
                      >
                        {section.title}
                      </Disclosure.Button>
                      {state.submenuOpen === section.url.id ? (
                        <ChevronUpIcon className={css.subCategoryButtonIcon} />
                      ) : (
                        <ChevronDownIcon
                          className={css.subCategoryButtonIcon}
                        />
                      )}
                    </div>
                    <Reveal
                      isOpen={state.submenuOpen === section.url.id}
                      className="transition duration-500 ease-out delay-200"
                      skipOutTransition
                      closedClassName="opacity-0 -translate-x-1"
                      openClassName="opacity-100 translate-x-0"
                    >
                      <Disclosure.Panel
                        static
                        as="div"
                        className={css.subCategoryPanel}
                      >
                        {section.items.map((item) =>
                          item.url.src ? (
                            <Link
                              href={item.url.src}
                              className={css.subCategoryPanelLink}
                              key={item.url.id}
                            >
                              {item.title}
                            </Link>
                          ) : null,
                        )}
                      </Disclosure.Panel>
                    </Reveal>
                  </>
                </Disclosure>
              )
            }
            return section.url.src ? (
              <div key={section.id} className="flex items-center group">
                {section.highlight && (
                  <ArrowRightIcon className="w-5 h-5 mr-1 translate-x-[-2px] text-primary-2" />
                )}
                <Link
                  href={section.url.src}
                  className={
                    section.highlight
                      ? css.subCategoryLinkHighlight
                      : css.subCategoryLink
                  }
                >
                  {section.title}
                </Link>
              </div>
            ) : null
          })}
        </div>
      ))}
    </>
  )
}

export default MegaMenuCategories
