import Link from 'components/ui/Link'
import Logo from 'components/ui/Logo'
import { NavigationCategoryItemColorThemeEnum } from 'framework/strapi/types'
import React, { FC } from 'react'

interface Props {
  colorTheme?: NavigationCategoryItemColorThemeEnum
}

const MainNavigationLogo: FC<Props> = ({ colorTheme }) => {
  const css = {
    logoWrapper: 'flex-shrink flex items-center',
    logo:
      'w-24 md:w-auto md:h-5 ' +
      (colorTheme === 'Dark' ? 'text-white' : 'text-primary'),
  }

  return (
    <div className={css.logoWrapper}>
      <Link href="/">
        <span className="sr-only">Eleiko</span>
        <Logo className={css.logo} />
      </Link>
    </div>
  )
}

export default MainNavigationLogo
