import React, { FC } from 'react'
import cn from 'classnames'
import { CommonBackground, CommonSectionSettings } from 'framework/strapi/types'
import {
  SectionLayoutSettings,
  SectionSettingsHeightStyles,
} from 'framework/common/types'
import { Dictionary } from '@reduxjs/toolkit'

interface Props extends Partial<CommonSectionSettings> {
  layoutSettings?: SectionLayoutSettings
  children?: React.ReactNode
  className?: string
}

const Section: FC<Props> = (props) => {
  const { id, children, anchor, tw_spacing, layoutSettings, className } = props
  // --- Spacing to previous Section

  const {
    preview,
    height,
    hasMarginTop,
    hasMarginBottom,
    isCovered,
    background,
  } = layoutSettings || {}
  const css = {
    section: cn(calculateMarginTop(tw_spacing!, hasMarginTop!), {
      [defaultMarginBottom]: hasMarginBottom,
    }),
    sectionInner: cn(
      'relative flex flex-col',
      heighStyles[height || 'Default'],
      calculatePaddingTop(isCovered, background),
      calculatePaddingBottom(isCovered, background),
    ),
  }

  return (
    <div
      id={anchor || id?.toString()}
      data-t-section
      className={cn(
        css.section,
        {
          'hover:outline-black': preview,
        },
        className,
      )}
    >
      <div className={css.sectionInner}>{children}</div>
    </div>
  )
}

const defaultMarginTop = 'mt-14 md:mt-20 lg:mt-24'
const defaultMarginBottom = 'mb-14 md:mb-20 lg:mb-24'

const calculateMarginTop = (marginTop: string, hasMarginTop: boolean) => {
  if (!marginTop) {
    return hasMarginTop ? defaultMarginTop : ''
  }
  switch (marginTop.toLowerCase()) {
    case 'yes':
      return defaultMarginTop
    case 'no':
      return ''
    case 'prose':
      return 'mt-10 md:mt-12 lg:mt-14'
    case 'preamble':
      return 'mt-10 md:mt-16 lg:mt-20'
    default:
      return marginTop
  }
}

// TODO Height for Navbar and top bar are hardcoded
// ScreenTop and ScreenTopFloatingNav are used to calculate correct banner height for the first section
// For LG: NavBar=96px TopBar=48px
// For MD down: Navbar=64px TopBar=48px
// eslint-disable-next-line no-unused-vars
const heighStyles: { [style in SectionSettingsHeightStyles]: string } = {
  // Screen modified to not cover 100%
  Screen: 'min-h-[calc(85vh)] lg:min-h-[calc(75vh)]',
  ScreenTop: 'min-h-[calc(85vh-112px)] lg:min-h-[calc(75vh-144px)]',
  // ScreenTop: 'min-h-[calc(100vh-112px)] lg:min-h-[calc(100vh-144px)]',
  ScreenTopFloatingNav: 'min-h-[calc(85vh-48px)] lg:min-h-[calc(75vh-48px)]',
  // ScreenTopFloatingNav: 'min-h-[calc(100vh-48px)]',
  Md: 'min-h-96 lg:min-h-112 xl:min-h-128 2xl:min-h-144',
  Navigation: 'min-h-16 lg:min-h-24',
  Default: '',
}

const paddingTopStyles: Dictionary<string> = {
  xs: 'pt-4 lg:pt-5',
  sm: 'pt-7 lg:pt-8',
  lg: 'pt-20 md:pt-28 lg:pt-40',
  xl: 'pt-40 md:pt-56 lg:pt-80',
  'mobile-none': 'md:pt-14 lg:pt-20',
  Default: 'pt-10 md:pt-14 lg:pt-20',
}

const paddingBottomStyles: Dictionary<string> = {
  xs: 'pb-4 lg:pb-5',
  sm: 'pb-7 lg:pb-8',
  lg: 'pb-20 md:pb-28 lg:pb-40',
  xl: 'pb-40 md:pb-56 lg:pb-80',
  'mobile-none': 'md:pb-14 lg:pb-20',
  Default: 'pb-10 md:pb-14 lg:pb-20',
}

const calculatePaddingTop = (
  isCovered?: boolean,
  background?: CommonBackground | null,
) => {
  if (!isCovered || !background) return ''

  if (background.tw_paddingTop) {
    if (paddingTopStyles[background.tw_paddingTop]) {
      return paddingTopStyles[background.tw_paddingTop]
    }
    return background.tw_paddingTop
  }
  return paddingTopStyles['Default']
}

const calculatePaddingBottom = (
  isCovered?: boolean,
  background?: CommonBackground | null,
) => {
  if (!isCovered || !background) return ''

  if (background.tw_paddingBottom) {
    if (paddingBottomStyles[background.tw_paddingBottom]) {
      return paddingBottomStyles[background.tw_paddingBottom]
    }
    return background.tw_paddingBottom
  }
  return paddingBottomStyles['Default']
}

export default Section
