import classNames from 'classnames'
import Button from 'components/ui/Button'
import Container from 'components/ui/Container'
import Link from 'components/ui/Link'
import { SectionLayoutSettings } from 'framework/common/types'
import { Footer, Navigation, NavigationItem } from 'framework/strapi/types'
import dynamic from 'next/dynamic'
import React, { FC, SVGProps } from 'react'
import Background from '../Background'
import Section from '../Section'
import useUIStore from 'store/ui'

const SelectLocation = dynamic(() => import('../SelectLocation'), {
  ssr: false,
})

interface Props extends Footer {
  navigation1?: Navigation
  navigation2?: Navigation
  navigation3?: Navigation
}

// TODO Footer icons are hardcoded
const footerIcons = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/eleiko',
    icon: (props: SVGProps<any>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/eleikosport/',
    icon: (props: SVGProps<any>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
]

const SectionFooter: FC<Props> = (props) => {
  const {
    settings,
    background,
    copyright,
    newsletterCtaText,
    newsletterDesc,
    newsletterTitle,
    navigation1,
    navigation2,
    navigation3,
  } = props
  const layoutSectionSettings: SectionLayoutSettings = {
    isCovered: true,
    preview: false,
    height: 'Default',
    background: background!,
    hasMarginTop: false,
    hasMarginBottom: false,
  }

  const ui = useUIStore()

  // Css
  const css = {
    title: 'text-h-base lg: text-h-lg xl:text-h-xl font-bold',
    desc: 'mt-4 text-base font-medium max-w-md',
    link: 'block font-medium text-base hover:underline',
    copyright: 'font-bold text-xs',
    bottom: 'mt-10 border-t_ pt-8 md:flex md:items-center md:justify-between',
    icon: 'h-6',
    iconLink: '',
    iconWrapper: '',
    location: '',
  }

  if (settings.colorTheme === 'Dark') {
    css.title = classNames(css.title, 'text-white')
    css.desc = classNames(css.desc, 'text-white')
    css.link = classNames(css.link, 'text-white')
    css.copyright = classNames(css.copyright, 'text-white')
    css.location = classNames(css.location, 'text-white')
    css.bottom = classNames(css.bottom, 'border-primary-dark')
    css.iconLink = classNames(css.iconLink, 'text-white hover:text-primary-2')
    css.iconWrapper = classNames(css.iconWrapper, 'text-white')
  } else {
    css.title = classNames(css.title, 'text-primary')
    css.desc = classNames(css.desc, 'text-primary')
    css.link = classNames(css.link, 'text-primary')
    css.copyright = classNames(css.copyright, 'text-primary')
    css.location = classNames(css.location, 'text-primary')
    css.bottom = classNames(css.bottom, 'border-primary')
    css.iconLink = classNames(css.iconLink, 'text-primary hover:text-primary-2')
    css.iconWrapper = classNames(css.iconWrapper, 'text-primary')
  }

  return (
    <Section {...settings} layoutSettings={layoutSectionSettings}>
      <Background {...background!} />
      <footer aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <Container layout="Fluid">
          {/* <div className="xl:grid xl:grid-cols-3 xl:gap-8"> */}
          <div className="flex flex-wrap justify-between gap-12 lg:gap-x-14">
            {/* <div className="xl:col-span-2"> */}
            <div className="flex">
              {[[navigation1, navigation2, navigation3]].map((nav, index) => (
                <div
                  key={index}
                  // className="flex flex-col items-start gap-y-12 md:grid md:grid-cols-2 md:gap-x-8"
                  className="flex flex-wrap items-start flex-1 gap-12 lg:gap-x-14"
                >
                  {nav.map((col, index: number) => {
                    if (!col) return null

                    return (
                      <div key={index}>
                        <h3 className={css.title}>{col.title}</h3>
                        <div role="list" className="mt-4 space-y-4">
                          {col.items?.map((item, i) => {
                            const { url, title } = item as NavigationItem
                            return (
                              url.src &&
                              title && (
                                <Link
                                  role="listitem"
                                  key={i}
                                  href={url.src}
                                  className={css.link}
                                >
                                  {title}
                                </Link>
                              )
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
            <div>
              <h3 className={css.title}>{newsletterTitle}</h3>
              <p className={css.desc}>{newsletterDesc}</p>
              <div className="mt-8">
                <Button
                  onClick={() => ui.open('newsletter')}
                  colorTheme={settings.colorTheme}
                >
                  {newsletterCtaText}
                </Button>
              </div>
            </div>
          </div>
          <div className={css.bottom}>
            <div className="flex flex-wrap items-center justify-between md:order-2 gap-x-10 gap-y-6">
              <div className="flex space-x-4">
                {footerIcons.map((item) => {
                  if (item.url)
                    return (
                      <Link
                        key={item.url}
                        href={item.url}
                        className={css.iconLink}
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className={css.icon} aria-hidden="true" />
                      </Link>
                    )

                  return (
                    <span key={item.name} className={css.iconWrapper}>
                      <span className="sr-only">{item.name}</span>
                      <item.icon className={css.icon} aria-hidden="true" />
                    </span>
                  )
                })}
              </div>
              <SelectLocation colorTheme={settings.colorTheme} />
            </div>
            <p className="mt-8 md:mt-0 md:order-1">
              <span className={css.copyright}>
                &copy; {new Date().getFullYear()} {copyright}
              </span>
            </p>
          </div>
        </Container>
      </footer>
    </Section>
  )
}

export default SectionFooter
