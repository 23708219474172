/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react'
import Link from 'components/ui/Link'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'

interface Props {
  logos: (CertificationType | string)[]
  colorTheme?: CommonSectionSettingsColorThemeEnum
  link?: boolean
  isSearchCard?: boolean
}

type CertificationType = 'iwf' | 'reddot' | 'wppo' | 'ipf' | 'ipc'

const Logos: FC<Props> = ({
  logos,
  colorTheme,
  link,
  isSearchCard = false,
}) => {
  let css = {
    wrapper: 'flex flex-wrap gap-6 items-center',
    logo: 'h-8',
  }

  if (isSearchCard) {
    css = {
      wrapper: 'flex flex-wrap gap-2 items-center',
      logo: 'h-6',
    }
  }

  if (logos) {
    return (
      <div className={css.wrapper}>
        {logos.map((l) => {
          const logo = l.toLocaleLowerCase() as CertificationType
          if (logo === 'iwf') {
            const img = (
              <img
                key={logo}
                src={`/logos/iwf-${
                  colorTheme === 'Dark' ? 'dark' : 'light'
                }.svg`}
                alt="iwf"
                className={css.logo}
              />
            )
            return link ? (
              <Link key={logo} href="iwf">
                {img}
              </Link>
            ) : (
              img
            )
          }
          if (logo === 'ipf') {
            const img = (
              <img
                key={logo}
                src={`/logos/ipf-${
                  colorTheme === 'Dark' ? 'dark' : 'light'
                }.svg`}
                alt="ipf"
                className={css.logo}
              />
            )
            if (link)
              return (
                <Link key={logo} href="/ipf" className={css.logo}>
                  {img}
                </Link>
              )
            return img
          }
          if (logo === 'wppo' || logo === 'ipc') {
            const img = (
              <img
                key={logo}
                src={`/logos/wppo-${
                  colorTheme === 'Dark' ? 'dark' : 'light'
                }.svg`}
                alt="ipf"
                className={css.logo}
              />
            )
            if (link)
              return (
                <Link key={logo} href="/wppo" className={css.logo}>
                  {img}
                </Link>
              )
            return img
          }
          if (logo === 'reddot') {
            const img = (
              <img
                key={logo}
                src={`/logos/reddot.svg`}
                alt="ipf"
                className={css.logo}
              />
            )
            return img
          }
          return null
        })}
      </div>
    )
  }

  return null
}

export default Logos
