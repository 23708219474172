import { useNProgress } from '@tanem/react-nprogress'
import { FC } from 'react'

interface Props {
  isAnimating: boolean
}

const ProgressBar: FC<Props> = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  })

  return (
    <div
      className="pointer-events-none"
      style={{
        opacity: isFinished ? 0 : 1,
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      <div
        className="bg-primary-2 h-1 w-full top-0 left-0 fixed z-50"
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
        }}
      ></div>
    </div>
  )
}

export default ProgressBar
