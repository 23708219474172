import { FC, SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {}

const Logo: FC<Props> = (props) => (
  <svg width={100} viewBox="0 0 200 36.71" {...props}>
    <path
      fill="currentColor"
      d="M11.07 25.48v-3.85h20.04v-7.28H11.07V11.24c0-1.79.91-2.7 2.7-2.7h17.76V.42H5.38C1.91.42 0 2.33 0 5.79v25.13c0 3.46 1.91 5.37 5.38 5.37h26.36v-8.12H13.77c-1.79 0-2.7-.91-2.7-2.69M47 25.27V.42H35.93v30.5c0 3.46 1.91 5.37 5.38 5.37h26.15v-8.33H49.7c-1.79 0-2.7-.91-2.7-2.69M81.87 25.48v-3.85h20.04v-7.28H81.87V11.24c0-1.79.91-2.7 2.7-2.7h17.76V.42H76.17c-3.47 0-5.38 1.91-5.38 5.37v25.13c0 3.46 1.91 5.37 5.38 5.37h26.36v-8.12H84.57c-1.8 0-2.7-.91-2.7-2.69M106.73.42h11.07v35.87h-11.07zM148.28 36.29h13.2L147.8 20.92c-1.21-1.36-1.62-2.15-1.62-3.14 0-1.16.62-2.25 1.21-2.88L159.58.42h-13.01l-9.74 13.66-.02.04c-.02.02-.03.05-.05.07l-.12.16h-3.35V.42H122.2v35.87h11.07V21.63H136.82l.12.17c.12.17.23.32.33.45l11.01 14.04z M180.2 28.38c-7.04 0-8.42-3.68-8.42-10.03s1.38-10.02 8.42-10.02 8.42 3.68 8.42 10.02c0 6.36-1.38 10.03-8.42 10.03m15.54-24.99C192.96.98 188.46 0 180.2 0c-8.26 0-12.77.98-15.55 3.39-2.98 2.58-4.26 7.06-4.26 14.97 0 7.91 1.27 12.39 4.26 14.97 2.78 2.41 7.28 3.39 15.55 3.39 8.26 0 12.76-.98 15.55-3.39 2.98-2.58 4.26-7.06 4.26-14.97-.01-7.92-1.28-12.39-4.27-14.97"
    ></path>
  </svg>
)

export default Logo
