import type {
  PageErrorProps,
  PageViewProps,
  TrackAddToCartProps,
  TrackAddToQuoteProps,
  TrackInitiateCheckoutProps,
  TrackLondonDynamicsProps,
  TrackProductDetailPageProps,
  TrackPurchaseProps,
} from './types'
import {
  GTMAddToCart,
  GTMAddToQuote,
  GTMBeginCheckout,
  GTMBeginCheckoutAbandonedCart,
  GTMLondonDynamics,
  GTMPageError,
  GTMPageView,
  GTMPurchase,
  GTMViewItem,
} from '../GoogleTagManager/events'

export const TrackPageView = (props: PageViewProps) => {
  GTMPageView(props)
}

export const TrackPageError = (props: PageErrorProps) => {
  GTMPageError(props)
}

export const TrackProductDetailPage = (props: TrackProductDetailPageProps) => {
  GTMViewItem(props)
}

export const TrackAddToCart = (props: TrackAddToCartProps) => {
  GTMAddToCart(props)
}

export const TrackAddToQuote = (props: TrackAddToQuoteProps) => {
  GTMAddToQuote(props)
}

export const TrackInitiateCheckout = (props: TrackInitiateCheckoutProps) => {
  GTMBeginCheckout(props)
  if (props.isAbandonedCart) {
    GTMBeginCheckoutAbandonedCart(props)
  }
}

export const TrackPurchase = (props: TrackPurchaseProps) => {
  GTMPurchase(props)
}

export const TrackLondonDynamics = (props: TrackLondonDynamicsProps) => {
  GTMLondonDynamics(props)
}
