import cn from 'classnames'
import Button from 'components/ui/Button'
import ButtonWrapper from 'components/ui/ButtonWrapper'
import Link from 'components/ui/Link'
import { NavigationFeaturedItem } from 'framework/strapi/types'
import React, { FC } from 'react'
import CmsImage from '../CmsImage'

interface Props {
  items: NavigationFeaturedItem[]
}
const MainNavigationFeatured: FC<Props> = ({ items }) => {
  const css = {
    featuredCol: '',
    featuredGrid: 'grid grid-cols-12 h-full',
    featuredColFull: 'col-span-12',
    featuredColHalf: 'col-span-6',
    featured1MediaWrapper:
      'aspect-w-3 aspect-h-2 min-h-full group overflow-hidden',
    featured2MediaWrapper:
      'aspect-w-4 aspect-h-5 min-h-full group overflow-hidden',
    featured3MediaWrapper_1: 'aspect-w-2 aspect-h-1 group overflow-hidden',
    featured3MediaWrapper_2: 'aspect-w-1 aspect-h-1 group overflow-hidden',
    featuredContainer:
      'absolute top-0 left-0 w-full h-full p-10 flex flex-col justify-end gap-y-7',
    featuredHeading:
      'font-bold text-h-3xl 2xl:text-h-4xl max-w-sm lg:max-w-md xl:max-w-md text-white',
    featured2Heading:
      'font-bold text-h-2xl xl:text-h-3xl 2xl:text-h-4xl max-w-sm lg:max-w-md xl:max-w-md text-white',
    featuredText:
      'font-medium leading-normal text-sm md:text-base max-w-sm md:max-w-md text-white',
  }

  return (
    <div className={css.featuredCol}>
      <div className={css.featuredGrid}>
        {items.map((feature, index) => {
          let featureMediaWrapper = css.featured1MediaWrapper
          let featuredCol = css.featuredColFull
          let featuredHeading = css.featuredHeading

          if (items.length === 2) {
            featuredCol = css.featuredColHalf
            featureMediaWrapper = css.featured2MediaWrapper
            featuredHeading = css.featured2Heading
          }

          if (items.length === 3) {
            if (index === 0) {
              featuredCol = css.featuredColFull
              featureMediaWrapper = css.featured3MediaWrapper_1
              featuredHeading = css.featured2Heading
            } else {
              featuredCol = css.featuredColHalf
              featureMediaWrapper = css.featured3MediaWrapper_2
              featuredHeading = css.featured2Heading
            }
          }

          return (
            <div key={index} className={featuredCol}>
              <div className={cn('relative', featureMediaWrapper)}>
                <CmsImage
                  size="5x4"
                  image={feature.image}
                  fill
                  className="object-cover object-center overflow-hidden duration-300 group-hover:scale-103"
                />
                {feature.tw_overlay && <div className={feature.tw_overlay} />}
                <div className={css.featuredContainer}>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex flex-col gap-y-2">
                      {feature.overline && (
                        <p className="font-medium text-white text-h-xs md:text-h-sm">
                          {feature.overline}
                        </p>
                      )}
                      {feature.heading && (
                        <h3 className={featuredHeading}>{feature.heading}</h3>
                      )}
                    </div>
                  </div>
                  {feature.ctas &&
                    ((feature.ctas.length === 1 && feature.ctas[0]!.title) ||
                      feature.ctas.length > 1) && (
                      <ButtonWrapper>
                        {feature.ctas.map(
                          (cta, index) =>
                            cta.url.src &&
                            cta.title && (
                              <Button
                                key={index}
                                href={cta.url.src}
                                inGroup={
                                  feature.ctas!.length === 1 &&
                                  !!feature.ctas![0]?.title
                                }
                                color="secondaryFill"
                                size="sm-md"
                                colorTheme="Dark"
                              >
                                {cta.title}
                              </Button>
                            ),
                        )}
                      </ButtonWrapper>
                    )}
                </div>
                {feature.ctas &&
                  feature.ctas.length === 1 &&
                  feature.ctas[0]!.url.src && (
                    <Link href={feature.ctas[0]!.url.src}>
                      {/* TODO What's here? */}
                    </Link>
                  )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MainNavigationFeatured
