import Modal from '../Modal'
import { useEffect, useState } from 'react'
import Link from 'components/ui/Link'
import { useRouter } from 'next/router'
import Button from 'components/ui/Button'
import tryJSONParse from 'utils/tryJSONParse'

type CookieConsentType = 'granted' | 'denied'

interface CookieConsent {
  ad_storage: CookieConsentType
  analytics_storage: CookieConsentType
  functionality_storage: CookieConsentType
}

const defaultConsent: CookieConsent = {
  ad_storage: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
}

const getConsent = (): CookieConsent | null => {
  const s = localStorage.getItem('cookie_consent')
  if (!s) return null
  const consent = tryJSONParse<CookieConsent>(s)
  return consent
}

const setConsent = (consent: CookieConsent) => {
  localStorage.setItem('cookie_consent', JSON.stringify(consent))
  window.gtag && window.gtag('consent', 'update', consent)
}

export const initConsent = () => {
  if (!window.gtag) return
  const consent = getConsent()
  if (consent) {
    window.gtag('consent', 'default', consent)
  } else {
    window.gtag('consent', 'default', defaultConsent)
  }
}

const CookieConsent: React.FC = ({}) => {
  const [show, setShow] = useState<boolean>(false)
  const router = useRouter()
  useEffect(() => {
    // Don't show cookie consent on the privacy policy page
    if (router.asPath == '/privacy-policy') {
      return
    }
    const consent = getConsent()
    if (!consent) {
      setShow(true)
    }
  }, [router.asPath])

  const handleAccept = (acceptAll: boolean) => {
    const c: CookieConsent = {
      ad_storage: acceptAll ? 'granted' : 'denied',
      analytics_storage: acceptAll ? 'granted' : 'denied',
      functionality_storage: 'granted',
    }
    setConsent(c)
    setShow(false)
  }

  return (
    <Modal isOpen={show}>
      <div className="p-4 flex flex-col gap-4">
        <p className="font-bold text-h-1xl lg:text-h-2xl text-primary">
          Your Privacy
        </p>
        <p>
          This site uses cookies to improve your experience. By clicking, you
          agree to our <Link href="privacy-policy">Privacy Policy.</Link>
        </p>
        <div className="flex gap-2 flex-wrap">
          <Button onClick={() => handleAccept(true)}>Accept all cookies</Button>
          <Button onClick={() => handleAccept(false)} color="secondary">
            Accept necessary cookies
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CookieConsent
