import { FC } from 'react'
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Reveal from 'components/common/Reveal'
import translate from 'utils/translate'
import cn from 'classnames'
import useUIStore from 'store/ui'

const Toast: FC = () => {
  const ui = useUIStore()
  return (
    <Reveal
      isOpen={ui.toast.isOpen}
      id="alert"
      aria-live="assertive"
      className={cn(
        'fixed flex items-center gap-2 bottom-4 right-4 p-4 ring-1 shadow-lg bg-white rounded-lg overflow-hidden transition ease-in-out duration-200 z-50',
        ui.toast.toast?.type === 'error'
          ? 'text-utility-error ring-utility-error'
          : ui.toast.toast?.type === 'warning'
            ? 'text-utility-error-light ring-utility-error-light'
            : 'text-primary ring-primary',
      )}
      openClassName="translate-y-0 opacity-100 sm:translate-x-0"
      closedClassName="translate-y-6 opacity-0 sm:translate-y-0 sm:translate-x-6"
    >
      {ui.toast.toast && (
        <>
          {ui.toast.toast.type === 'error' ? (
            <ExclamationCircleIcon className="w-6 h-6" aria-hidden="true" />
          ) : ui.toast.toast.type === 'warning' ? (
            <ExclamationTriangleIcon className="w-6 h-6" aria-hidden="true" />
          ) : null}
          {ui.toast.toast.title && (
            <span className="font-bold">{ui.toast.toast.title}</span>
          )}
          {ui.toast.toast.message && (
            <span className="text-sm">{ui.toast.toast.message}</span>
          )}
          {ui.toast.toast.messages && (
            <ul>
              {ui.toast.toast.messages.map((msg) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          )}
          <div className="flex flex-shrink-0 ml-4">
            <button onClick={() => ui.closeToast()}>
              <span className="sr-only">{translate('close')}</span>
              <XMarkIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </>
      )}
    </Reveal>
  )
}

export default Toast
