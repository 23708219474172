/**
 * Tries to parse a value to JSON without throwing an exception.
 * @param val value to parse
 * @returns The parsed value or null
 */
const tryJSONParse = <T extends unknown>(val: string): T | null => {
  try {
    return JSON.parse(val) as T
  } catch {
    console.warn(`Could not parse ${val} to JSON`)
    return null
  }
}

export default tryJSONParse
