import type {
  PageErrorProps,
  PageViewProps,
  TrackAddToCartProps,
  TrackAddToQuoteProps,
  TrackInitiateCheckoutProps,
  TrackLondonDynamicsProps,
  TrackProductDetailPageProps,
  TrackPurchaseProps,
} from '../common/types'
import type { GTM } from './Gtm'

export const GTMPageView = ({ url, debug }: PageViewProps) => {
  if (!window.dataLayer) return
  if (debug) {
    console.log('GTM PageView', url)
  }
  window.dataLayer.push({
    event: 'route_complete',
    path: url,
  })
}

export const GTMPageError = ({ error, url, debug }: PageErrorProps) => {
  if (!window.dataLayer) return
  if (debug) {
    console.log('GTM PageError', url, error)
  }
  window.dataLayer.push({
    event: 'route_error',
    path: url,
    error: JSON.stringify(error),
  })
}

export const GTMViewItem = ({
  debug,
  product,
}: TrackProductDetailPageProps) => {
  if (!window.dataLayer) {
    return
  }
  const params: GTM.EventParameters = {
    event: 'view_item',
    value: product.price!,
    items: [
      {
        item_id: product.variantId,
        item_name: product.variantName,
        price: product.price!,
      },
    ],
  }
  if (debug) {
    console.log('GTMTrackDetailPage', params)
  }
  window.dataLayer.push(params)
}

export const GTMAddToQuote = ({
  price,
  currency,
  items,
  debug,
}: TrackAddToQuoteProps) => {
  if (!window.dataLayer) return
  const params: GTM.EventParameters = {
    event: 'add_to_quote',
    value: price,
    currency,
    items: items.map(({ id, name, price, quantity }) => ({
      item_id: id,
      item_name: name,
      price,
      quantity,
    })),
  }
  if (debug) {
    console.log('GTMAddToQuote', params)
  }
  window.dataLayer.push(params)
}

export const GTMAddToCart = ({
  price,
  currency,
  items,
  debug,
}: TrackAddToCartProps) => {
  if (!window.dataLayer) return
  const params: GTM.EventParameters = {
    event: 'add_to_cart',
    value: price,
    currency,
    items: items.map(({ id, name, price, quantity }) => ({
      item_id: id,
      item_name: name,
      price,
      quantity,
    })),
  }
  if (debug) {
    console.log('GTMTrackAddToCart', params)
  }
  window.dataLayer.push(params)
}

export const GTMBeginCheckout = ({
  cart,
  debug,
}: TrackInitiateCheckoutProps) => {
  if (!window.dataLayer) return
  if (cart.loading) return

  const params: GTM.EventParameters = {
    event: 'begin_checkout',
    value: cart.items.reduce(
      (a: number, i: { price: number; quantity: number }) => {
        a += i.price * i.quantity
        return a
      },
      0,
    ),
    items: cart.items
      .map((i) => {
        return {
          item_id: i.variantId,
          item_name: i.name,
          quantity: i.quantity,
        }
      })
      .filter(Boolean),
  }
  if (debug) {
    console.log('GoogleTrackInitiateCheckout', params)
  }
  window.dataLayer.push(params)
}

export const GTMBeginCheckoutAbandonedCart = ({
  cart,
  debug,
}: TrackInitiateCheckoutProps) => {
  if (!window.dataLayer) return
  if (cart.loading) return

  const params: GTM.EventParameters = {
    event: 'begin_checkout_abandoned_cart',
    value: cart.items.reduce((a, i) => {
      a += i.price * i.quantity
      return a
    }, 0),
    items: cart.items
      .map((i) => {
        return {
          item_id: i.variantId,
          item_name: i.name,
          quantity: i.quantity,
        }
      })
      .filter(Boolean),
  }
  if (debug) {
    console.log('GoogleTrackInitiateCheckoutAbandonedCart', params)
  }
  window.dataLayer.push(params)
}

export const GTMPurchase = (props: TrackPurchaseProps) => {
  if (!window.dataLayer) return
  const params: GTM.EventParameters = {
    event: 'purchase',
    value: props.order.value,
    currency: props.order.currency,
    transaction_id: props.order.transactionId,
    tax: props.order.tax,
    shipping: props.order.shipping,
    coupon: props.order.coupon,
    items: props.order.items.map(({ id, price, quantity, name }) => ({
      item_id: id,
      item_name: name,
      price,
      quantity,
    })),
    email: props.order.email,
  }
  if (props.debug) {
    console.log('GoogleTrackPurchase', params)
  }
  window.dataLayer.push(params)
}

export const GTMLondonDynamics = ({
  type,
  data,
  debug,
}: TrackLondonDynamicsProps) => {
  if (!window.dataLayer) return

  console.log('GTMLondonDynamics', type, data)
  const params: GTM.EventParameters = {
    event: 'london_dynamics_' + type,
    value: data,
  }
  if (debug) {
    console.log('GTMLondonDynamics', params)
  }
  window.dataLayer.push(params)
}