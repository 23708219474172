const getAbsoluteImageUrl = (url: string) => {
  if (url.startsWith('https')) {
    return url
  }

  if (url.toLowerCase().includes('digitaloceanspaces.com')) {
    return 'https://' + url
  }
  return process.env.NEXT_PUBLIC_CMS_API_URL + url
}

export default getAbsoluteImageUrl
