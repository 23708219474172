import { StrapiFileComponent } from "framework/strapi/types"

export const toStrapiFileComponent = (image: any) => {
    const cantoImage = {
        url: image.url,
        mime: image.mime,
        name: image.name,
        alternativeText: image.alternativeText,
        caption: image.caption,
        formats: image.formats
      } as StrapiFileComponent

      return cantoImage;
  }