import { CommonBackground } from 'framework/strapi/types'
import { FC } from 'react'
import { Dictionary } from 'utils/dictionary'

const captionPlacements: Dictionary<string> = {
  'top-right_md:bottom-right':
    'inline-block absolute top-0 right-0 md:top-auto md:bottom-0 transform origin-bottom-right md:origin-bottom-left -rotate-90 -translate-y-full md:translate-y-0 md:translate-x-full mr-[-2px] mt-[-0.4em] md:mb-[-1px]',
  'top-right-nav_md:bottom-right':
    'inline-block absolute top-16 right-0 md:top-auto md:bottom-0 transform origin-bottom-right md:origin-bottom-left -rotate-90 -translate-y-full md:translate-y-0 md:translate-x-full mr-[-2px] mt-[-0.4em] md:mb-[-1px]',
  'top-right_md:bottom-right_lg:center':
    'inline-block absolute top-0 right-0 md:top-auto md:bottom-0 lg:right-auto lg:bottom-auto lg:left-1/2 lg:top-1/2 transform origin-bottom-right md:origin-bottom-left lg:origin-center -rotate-90 -translate-y-full md:translate-y-0 md:translate-x-full lg:-translate-x-1/2 mr-[-2px] lg:mr-0 lg:-ml-[-1px] mt-[-0.4em] lg:mt-0 md:mb-[-1px] lg:mb-0',
  'md:bottom-right_lg:center':
    'hidden md:inline-block absolute md:right-0 md:bottom-0 lg:right-auto lg:bottom-auto lg:left-1/2 lg:top-1/2 transform origin-bottom-right md:origin-bottom-left lg:origin-center -rotate-90 -translate-y-full md:translate-y-0 md:translate-x-full lg:-translate-x-1/2 mr-[-2px] lg:mr-0 lg:ml-[-1px] mt-[-0.4em] lg:mt-0 md:mb-[-1px] lg:mb-0',
  center:
    'inline-block absolute top-1/2 left-1/2 transform origin-center -rotate-90 -translate-x-1/2 ml-[-1px]',
  'lg:center':
    'hidden lg:inline-block absolute top-1/2 left-1/2 transform origin-center -rotate-90 -translate-x-1/2 ml-[-1px]',
  'top-left':
    'inline-block absolute top-0 left-0 transform -translate-x-full origin-top-right -rotate-90 ml-[-2px] mt-[-0.4em]',
  'bottom-left':
    'inline-block absolute bottom-0 left-0 transform origin-top-left -rotate-90 translate-y-full ml-[-2px]',
  'lg:bottom-left':
    'hidden lg:inline-block absolute bottom-0 left-0 transform origin-top-left -rotate-90 translate-y-full ml-[-2px]',
  'top-right':
    'inline-block absolute top-0 right-0 transform origin-bottom-right -rotate-90 -translate-y-full mr-[-2px] mt-[-0.4em]',
  'bottom-right':
    'inline-block absolute bottom-0 right-0 transform origin-bottom-left -rotate-90 translate-x-full mr-[-2px] mb-[-1px]',
}

const BackgroundCaption: FC<
  Pick<CommonBackground, 'captionText' | 'tw_captionPlacement'>
> = ({ captionText, tw_captionPlacement: captionPlacement }) => {
  const css = {
    captionWrapper:
      'z-10 pointer-events-none absolute top-10 bottom-10 left-6 right-6 md:top-14 md:bottom-14 md:left-14 md:right-14 lg:top-20 lg:bottom-20 lg:left-20 lg:right-20',
    captionPlacement: captionPlacements[captionPlacement!],
    captionText:
      'text-xs font-medium leading-none uppercase tracking-[0.4em] whitespace-nowrap text-accent',
  }

  if (!css.captionPlacement) {
    css.captionPlacement = captionPlacements['top-right_md:bottom-right']
  }

  return (
    <div className={css.captionWrapper}>
      <div className={css.captionPlacement}>
        <div className={css.captionText}>{captionText}</div>
      </div>
    </div>
  )
}

export default BackgroundCaption
